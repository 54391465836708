import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setPhoneFormat } from '../../state/settings';
import { RootStateType } from '../../state/store';
import { PhoneFormat } from '../../types';
import { Dropdown, DropdownItem } from './common/Dropdown';
import { SettingsItem } from './styles/SettingsItem.styles';

const PhonePrefixElement = styled.div`
  ${SettingsItem};
`;
const FlagImageElement = styled.img`
  margin-right: 5px;
`;

export const PhonePrefix = () => {
  const {
    phoneNumberOptions: { phoneFormats },
  } = useSelector((e: RootStateType) => e.global.options!.i18n);
  const dispatch = useDispatch();
  const { phoneFormat } = useSelector((e: RootStateType) => e.settings);
  const { t } = useTranslation('settings');

  const onDropdownItemClick = (format: PhoneFormat) => {
    dispatch(setPhoneFormat({ format }));
  };

  return (
    <PhonePrefixElement>
      <span>{t('personalization.phonecall.headline')}</span>

      <Dropdown
        placeholder={
          <Placeholder
            imageUrl={phoneFormat.imageUrl}
            text={phoneFormat.prefix}
          />
        }
      >
        {(onClose) =>
          phoneFormats.map((format) => (
            <DropdownItem
              key={format.code}
              onClick={() => {
                onDropdownItemClick(format);
                onClose();
              }}
            >
              <Placeholder imageUrl={format.imageUrl} text={format.prefix} />
            </DropdownItem>
          ))
        }
      </Dropdown>
    </PhonePrefixElement>
  );
};

type PlaceholderProps = { imageUrl: string; text: string };
const Placeholder = ({ imageUrl, text }: PlaceholderProps) => (
  <>
    <FlagImageElement src={imageUrl} alt="" />

    <span>{text}</span>
  </>
);
