import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { hide } from '../../state/chat';
import { changeContent, setCanClose } from '../../state/view';
import { Button } from './Button';

type Props = {
  elementRef: React.RefObject<HTMLButtonElement>;
  onCloseClick: () => void;
};

export const OpenChatWarningModal = ({ elementRef, onCloseClick }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('modals');

  const onClick = () => {
    dispatch(setCanClose(true));
    dispatch(hide());
    dispatch(changeContent({ direction: 'backward' }));

    onCloseClick();
  };

  return (
    <div>
      <Button buttonRef={elementRef} onClick={onClick}>
        {t('closeWarning.button')}
      </Button>
    </div>
  );
};
