import { isEmpty } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled, {
  css,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { useWindowSize } from '../../hooks/useWindowSize';
import { setCode } from '../../state/callcode';
import { showWarningModal } from '../../state/modal';
import { hideNotification } from '../../state/notification';
import { setPhoneFormat } from '../../state/settings';
import { RootStateType } from '../../state/store';
import {
  afterGrow,
  afterShrink,
  loadPersistedState,
  resetActiveContent,
  setActive,
  setForceClose,
  setHeight,
  setMoving,
  setMovingStateStyles,
  toggleBrandColor,
  toggleContent,
} from '../../state/view';
import {
  Activable,
  ContentType,
  WidgetOptions,
  WidgetPosition,
} from '../../types';
import { CallOrder } from '../callorders/CallOrder';
import { Chat } from '../chat/Chat';
import { Content, ReferenceContent } from '../content/Content';
import { Help } from '../help/Help';
import { Message } from '../messages/Message';
import { CloseWarningModal } from '../modals/CloseWarningModal';
import { LandscapeWarningModal } from '../modals/LandscapeWarningModal';
import { Modal } from '../modals/Modal';
import { Notification } from '../notifications/Notification';
import { Operators } from '../operators/Operators';
import { PhoneCall } from '../phonecalls/PhoneCall';
import { OpenCloseIcon } from './OpenCloseIcon';

type WidgetElementProps = {
  animationStyles:
    | FlattenSimpleInterpolation
    | FlattenInterpolation<ThemeProps<any>>;
  position: WidgetPosition;
  color: 'white' | 'black';
  height: number;
  brandColor: string;
  showBrandColor: boolean;
  canShow: boolean;
};

type InternalWidgetElementProps = {
  className?: string;
  innerRef: React.RefObject<HTMLDivElement>;
  onKeyUp: (e: React.KeyboardEvent<HTMLDivElement>) => void;
} & WidgetElementProps;

const InternalWidgetElement: React.FC<InternalWidgetElementProps> = ({
  className,
  innerRef,
  onKeyUp,
  children,
  animationStyles,
  position,
  color,
  height,
  brandColor,
  showBrandColor,
  canShow,
  ...rest
}) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div ref={innerRef} {...rest} className={className} onKeyUp={onKeyUp}>
    {children}
  </div>
);

const WidgetElement = styled(InternalWidgetElement)<WidgetElementProps>`
  --widget-content-padding: 10px;
  --widget-width: 370px;
  --widget-subcontent-padding: 15px;
  --widget-height: ${({ height }) => `${height}px`};

  @media only screen and (max-width: 599px) and (orientation: portrait),
    (min-device-width: 320px) and (max-device-width: 812px) and (orientation: landscape) {
    --widget-width: 100%;
    --widget-height: 100%;
  }

  --widget-background-color: ${({ color }) =>
    color === 'black' ? '#212121' : '#f3f3f4'};
  --widget-color: ${({ color }) => (color === 'white' ? '#212121' : '#ffffff')};
  --widget-color-secondary: ${({ color }) =>
    color === 'white' ? '#5d5d5d99' : '#f2f2f2'};
  --widget-content-box-shadow-color: ${({ color }) =>
    color === 'white' ? '#eaeaea' : '#212121'};
  --widget-brand-color: ${({ brandColor }) => brandColor};
  --widget-content-background-color: ${({ color }) =>
    color === 'white' ? '#ffffff' : '#474747'};
  --widget-danger-color: #ed5218;
  --widget-input-border-color: #dbdbdb;
  --widget-color-green: #44dc46;
  --widget-color-green-rgb: 68, 220, 70;

  -webkit-text-size-adjust: 100%;
  font-family: 'neue-haas-unica', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  box-sizing: border-box;
  font-size: 14px;
  user-select: none;
  width: 50px;
  height: 50px;
  background-color: var(--widget-background-color);
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  position: fixed;
  bottom: 25px;
  z-index: 1;

  ${({ position }) =>
    position === 'bottom-left' ? 'left: 25px' : 'right: 25px'};

  ${({ showBrandColor }) =>
    showBrandColor &&
    css`
      background-color: var(--widget-brand-color);
    `}

  ${({ animationStyles }) => animationStyles}

	${({ canShow }) =>
    canShow
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}

	* {
    box-sizing: border-box;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
  }
`;

type OpenCoseIconWrapperElementProps = {
  showBrandColor: boolean;
} & Activable;

const OpenCoseIconWrapperElement = styled.div<OpenCoseIconWrapperElementProps>`
  position: absolute;
  background-color: var(--widget-background-color);
  border-radius: 50%;
  transition: width 0.75s ease, height 0.75s ease, top 0.75s ease,
    right 0.75s ease;
  will-change: width, height, top, right;
  width: 50px;
  height: 50px;
  top: 0;
  right: 0;

  ${({ active }) =>
    active &&
    css`
      width: 24px;
      height: 24px;
      top: 10px;
      right: 10px;
      border-radius: 0;
    `}

  ${({ showBrandColor }) =>
    showBrandColor &&
    css`
      background-color: var(--widget-brand-color);
    `}
`;
const ContentWrapperElement = styled.div<Activable>`
  height: 0;
  overflow: hidden;
  transition: height 0.5s ease, opacity 0.75s ease;
  color: var(--widget-color);
  opacity: 0;
  user-select: auto;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      height: var(--widget-height);
      overflow: auto;
    `}
`;

const ContentElement = styled.div<{ activeContent: ContentType }>`
  padding: var(--widget-content-padding);
  height: ${({ activeContent }) =>
    activeContent === 'main' ? 'unset' : '100%'};
`;

type Props = {
  options: WidgetOptions;
};

export const Widget: React.FC<Props> = React.memo(() => {
  const {
    global: { options },
    view: {
      height,
      active,
      moving,
      canGrow,
      canShrink,
      animationStyles,
      contentActive,
      showBrandColor,
      activeContent,
      persistState,
      canClose,
      forceClose,
    },
    settings: { phoneFormat },
  } = useSelector((e: RootStateType) => e);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const isMobileAndOrLandscape = useMediaQuery({
    query:
      'only screen and (max-width: 599px) and (orientation: portrait), (min-device-width: 320px) and (max-device-width: 812px) and (orientation: landscape)',
  });
  const { height: windowHeight } = useWindowSize();

  const widgetRef = React.useRef<HTMLDivElement>(null);
  const openCloseIconRef = React.useRef<HTMLDivElement>(null);

  const toggleWidget = React.useCallback(() => {
    if (canClose) {
      dispatch(hideNotification());
      dispatch(setActive(!active));
      dispatch(setCode(''));
    }

    if (!canClose) {
      dispatch(
        showWarningModal({
          title: 'closeWarning.title',
          text: 'closeWarning.text',
          content: CloseWarningModal,
        })
      );
    }
  }, [dispatch, active, canClose]);
  const onWidgetClick = React.useCallback(() => {
    if (!moving) {
      toggleWidget();
    }
  }, [moving, toggleWidget]);
  const onWidgetKeyUp = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (
        e.key === 'Enter' &&
        document.activeElement === openCloseIconRef.current
      ) {
        toggleWidget();
      }
    },
    [openCloseIconRef, toggleWidget]
  );

  const growDone = React.useCallback(() => {
    dispatch(afterGrow());
  }, [dispatch]);
  const beforeGrowDone = React.useCallback(() => {
    dispatch(
      setMovingStateStyles({
        state: 'grow',
        position: options!.position,
      })
    );
    dispatch(toggleContent());

    setTimeout(growDone, 1000);
  }, [growDone, dispatch, options]);
  const afterShrinkDone = React.useCallback(() => {
    dispatch(afterShrink());
    dispatch(toggleBrandColor());
    dispatch(resetActiveContent());
  }, [dispatch]);
  const shrinkDone = React.useCallback(() => {
    dispatch(
      setMovingStateStyles({
        state: 'after-shrink',
        position: options!.position,
      })
    );

    setTimeout(afterShrinkDone, 100);
  }, [afterShrinkDone, dispatch, options]);

  React.useEffect(() => {
    if (canGrow && active) {
      dispatch(toggleBrandColor());
      dispatch(setMoving(true));
      dispatch(
        setMovingStateStyles({
          state: 'before-grow',
          position: options!.position,
        })
      );

      setTimeout(beforeGrowDone, 100);
    }
  }, [active, canGrow, beforeGrowDone, dispatch, options]);

  React.useEffect(() => {
    if (canShrink && !active) {
      dispatch(setMoving(true));
      dispatch(
        setMovingStateStyles({
          state: 'shrink',
          position: options!.position,
        })
      );
      dispatch(toggleContent());

      setTimeout(shrinkDone, 1000);
    }
  }, [active, canShrink, shrinkDone, dispatch, options]);

  React.useEffect(() => {
    if (!active && height === 0) {
      dispatch(setHeight(Math.min(727, windowHeight)));
    }
  }, [active, height, dispatch, persistState, windowHeight]);

  React.useLayoutEffect(() => {
    if (persistState && !active) {
      dispatch(loadPersistedState());
    }
  }, [active, dispatch, persistState]);

  React.useEffect(() => {
    if (canClose && forceClose) {
      toggleWidget();
      dispatch(setForceClose(false));
    }
  }, [canClose, forceClose, toggleWidget, dispatch]);

  React.useEffect(() => {
    if (isEmpty(phoneFormat)) {
      const prefixByI18n = options!.i18n.phoneNumberOptions.phoneFormats.find(
        (format) => format.code === i18n.language
      );
      const prefixByDefault = options!.i18n.phoneNumberOptions.phoneFormats.find(
        (format) => format.code === options!.i18n.phoneNumberOptions.defaultCode
      );

      dispatch(
        setPhoneFormat({
          format: prefixByI18n ?? prefixByDefault!,
        })
      );
    }
  }, [phoneFormat, dispatch, i18n.language, options]);

  const getContent = () => {
    /* do not move Content into getContent! that will break animations! */
    switch (activeContent) {
      case 'operators':
        return <Operators />;
      case 'message':
        return <Message />;
      case 'callorder':
        return <CallOrder />;
      case 'chat':
        return <Chat />;
      case 'phonecall':
        return <PhoneCall />;
      case 'help':
        return <Help />;
      default:
        return null;
    }
  };

  return (
    <WidgetElement
      innerRef={widgetRef}
      onKeyUp={onWidgetKeyUp}
      animationStyles={animationStyles}
      position={options!.position}
      color={options!.theme.color}
      height={height}
      brandColor={options!.theme.brandColor}
      showBrandColor={showBrandColor}
      canShow={!persistState}
    >
      <Notification position={options!.position} />

      <OpenCoseIconWrapperElement
        role="button"
        aria-label="Otevřít/zavřít widget"
        tabIndex={0}
        ref={openCloseIconRef}
        onClick={onWidgetClick}
        active={contentActive}
        showBrandColor={showBrandColor}
      >
        <OpenCloseIcon active={active} />
      </OpenCoseIconWrapperElement>

      <ContentWrapperElement active={contentActive}>
        <ContentElement activeContent={activeContent}>
          {/* do not move Content into getContent! that will break animations! */}
          <Content />

          {getContent()}
        </ContentElement>
      </ContentWrapperElement>

      <Modal />

      {isMobileAndOrLandscape && <LandscapeWarningModal />}

      {/* only used as reference to set widget height */}
      {!contentActive && <ReferenceContent />}
    </WidgetElement>
  );
});
