import { css } from 'styled-components';
import { WidgetPosition } from '../../types';

export const beforeGrowCss = css`
  transition: border-radius 0.1s ease;
  will-change: border-radius;

  border-radius: 8px;
`;

export const growCss = ({ position }: { position: WidgetPosition }) => css`
  transition: all 1s ease;
  will-change: width, height, left, right, top, bottom;

  width: var(--widget-width);
  height: var(--widget-height);
  border-radius: 8px;

  @media only screen and (max-width: 599px) and (orientation: portrait),
    (min-device-width: 320px) and (max-device-width: 812px) and (orientation: landscape) {
    width: 100%;
    height: 100%;
    bottom: 0;

    ${() =>
      position === 'bottom-left'
        ? css`
            left: 0;
          `
        : css`
            right: 0;
          `}
  } ;
`;

export const growWithoutAnimationCss = () => css`
  width: var(--widget-width);
  height: var(--widget-height);
  border-radius: 8px;
`;

export const shrinkCss = css`
  transition: all 1s ease;
  will-change: width, height, left, right, top, bottom;

  width: 50px;
  height: 50px;
  border-radius: 8px;
`;

export const afterShrinkCss = css`
  transition: 0.1s ease;
  will-change: border-radius;
  transition-property: border-radius;

  border-radius: 50%;
`;
