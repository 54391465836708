import styled from 'styled-components';
import { Status } from '../../types';

const PhotoWrapper = styled.div<{ status: Status }>`
  --status-color: ${({ status }) =>
    status === 'online'
      ? 'var(--widget-color-green)'
      : 'var(--widget-danger-color)'};
  --grayscale: ${({ status }) => (status === 'online' ? '0%' : '100%')};

  position: relative;
  margin-right: var(--widget-content-padding);
`;
const PhotoElement = styled.img`
  --dimensions: 40px;

  display: block;
  min-width: var(--dimensions);
  max-width: var(--dimensions);
  min-height: var(--dimensions);
  max-height: var(--dimensions);
  border-radius: 50%;
  border: 2px solid var(--status-color);
  filter: grayscale(var(--grayscale));
`;
const StatusDot = styled.div`
  --dimensions: 9px;

  position: absolute;
  width: var(--dimensions);
  height: var(--dimensions);
  background-color: var(--status-color);
  border-radius: 50%;
  top: 5px;
  right: 0;
`;

type Props = {
  status: Status;
  fullName: string;
  photoUrl: string;
};

export const Photo = ({ status, fullName, photoUrl }: Props) => (
  <PhotoWrapper status={status}>
    <PhotoElement src={photoUrl} alt={fullName} />

    <StatusDot />
  </PhotoWrapper>
);
