import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useWindowSize } from '../../hooks/useWindowSize';
import { RootStateType } from '../../state/store';
import { setHeight } from '../../state/view';
import { ContentType } from '../../types';
import { Actions } from './Actions';
import { MainView } from './MainView';

const ReferenceContentElement = styled.div`
  opacity: 0;
  display: block;
  position: fixed;
  top: -9999px;
  left: -9999px;
  width: var(--widget-width);
`;
const ContentElement = styled.div<{ activeContent: ContentType }>`
  display: ${({ activeContent }) =>
    activeContent === 'main' ? 'block' : 'none'};
`;

export const Content = () => {
  const { activeContent } = useSelector((e: RootStateType) => e.view);

  return (
    <ContentElement activeContent={activeContent}>
      <Actions />

      <MainView />
    </ContentElement>
  );
};

export const ReferenceContent = () => {
  const { activeSections } = useSelector(
    (e: RootStateType) => e.global.options!
  );
  const dispatch = useDispatch();
  const contentRef = React.useRef<HTMLDivElement>(null);
  const { height } = useWindowSize();

  React.useEffect(() => {
    if (contentRef?.current) {
      setTimeout(() => {
        dispatch(
          setHeight(
            Math.min(
              contentRef!.current!.getBoundingClientRect().height + 23,
              height - 50
            )
          )
        );
      }, 100);
    }
  }, [dispatch, activeSections, height]);

  return (
    <ReferenceContentElement ref={contentRef}>
      <Actions />

      <MainView />
    </ReferenceContentElement>
  );
};
