import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { PhoneFormat } from '../types';

type DeactivatePayload = { initiator: 'widget' } | { initiator: 'settings' };
type SetPhoneFormatPayload = {
  format: PhoneFormat;
};

export type SettingsState = {
  active: boolean;
  phoneFormat: PhoneFormat;
};

export const deactivate = createAction(
  '@settings/DEACTIVATE'
)<DeactivatePayload>();
export const toggleMenu = createAction('@settings/TOGGLE_MENU')();
export const setPhoneFormat = createAction(
  '@settings/SET_PHONE_PREFIX'
)<SetPhoneFormatPayload>();

export type SettingsAction =
  | ActionType<typeof deactivate>
  | ActionType<typeof toggleMenu>
  | ActionType<typeof setPhoneFormat>;

export const settingsReducer = createReducer<SettingsState, SettingsAction>({
  active: false,
  phoneFormat: {} as PhoneFormat,
})
  .handleAction(deactivate, (state) => ({ ...state, active: false }))
  .handleAction(toggleMenu, (state) => ({ ...state, active: !state.active }))
  .handleAction(setPhoneFormat, (state, action) => ({
    ...state,
    phoneFormat: action.payload.format,
  }));
