import styled from 'styled-components';
import { Operator as OperatorType } from '../../../types';
import { BasicInfo } from './BasicInfo';
import { Photo } from './Photo';

const OperatorElement = styled.div`
  display: flex;
  align-items: center;
`;

type Props = {
  operator: OperatorType;
};

export const Operator = ({ operator }: Props) => (
  <OperatorElement>
    <Photo fullName={operator.fullName} photoUrl={operator.photoUrl} />

    <BasicInfo fullName={operator.fullName} tag={operator.tag} />
  </OperatorElement>
);
