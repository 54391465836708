import React from 'react';
import styled, { css } from 'styled-components';
import { Activable, Operator as OperatorType } from '../../types';
import { BasicInfo } from './BasicInfo';
import { CallOrderBubble } from './bubbles/CallOrder';
import { ChatBubble } from './bubbles/Chat';
import { MessageBubble } from './bubbles/Message';
import { PhoneCallBubble } from './bubbles/PhoneCall';
import { Photo } from './Photo';
import { Toggle } from './Toggle';

const OperatorElement = styled.div`
  padding: var(--widget-content-padding) calc(2 * var(--widget-content-padding));
  border-top: 1px solid var(--widget-background-color);

  display: flex;
  flex-direction: column;
`;
const MainInfoElement = styled.div`
  display: flex;
  flex-direction: row;
`;
const ActionsElement = styled.div<Activable>`
  transition: height 0.2s ease-in-out;
  overflow: hidden;
  height: 0;

  ${({ active }) =>
    active &&
    css`
      height: var(--height);
    `};

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding-top: var(--widget-content-padding);
    padding-left: calc(40px + var(--widget-content-padding));
  }
`;

export const Operator = ({
  operatorId,
  fullName,
  tag,
  photoUrl,
  status,
}: OperatorType) => {
  const [open, setOpen] = React.useState(false);
  const [height, setHeight] = React.useState('0px');
  const actionsRef = React.useRef<HTMLDivElement>(null);

  const onClick = () => {
    setHeight(`${actionsRef?.current?.scrollHeight}px`);
    setOpen(!open);
  };

  return (
    <OperatorElement>
      <MainInfoElement onClick={onClick}>
        <Photo status={status} fullName={fullName} photoUrl={photoUrl} />

        <BasicInfo fullName={fullName} tag={tag} />

        <Toggle open={open} />
      </MainInfoElement>

      <ActionsElement
        ref={actionsRef}
        active={open}
        style={
          {
            '--height': height,
          } as React.CSSProperties
        }
      >
        <div>
          <ChatBubble operatorId={operatorId} status={status} />
          <PhoneCallBubble operatorId={operatorId} status={status} />
          <MessageBubble operatorId={operatorId} status="online" />
          <CallOrderBubble operatorId={operatorId} status="online" />
        </div>
      </ActionsElement>
    </OperatorElement>
  );
};
