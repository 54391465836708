import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { changeSoundPreference } from '../../state/notification';
import { RootStateType } from '../../state/store';
import { SettingsItem } from './styles/SettingsItem.styles';

const SoundsElement = styled.div`
  ${SettingsItem};
`;

const ToggleElement = styled.span`
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  width: 46px;
  height: 26px;
  background-color: var(--widget-input-border-color);
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 42px;
    height: 22px;
    background-color: #ffffff;
    border-radius: 11px;
    transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
    transition: all 0.25s linear;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: #ffffff;
    border-radius: 11px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    transform: translate3d(2px, 2px, 0);
    transition: all 0.2s ease-in-out;
  }
`;
const InputElement = styled.input`
  display: none;

  &:checked + ${ToggleElement} {
    background-color: var(--widget-color-green);
  }

  &:checked + ${ToggleElement}::before {
    transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
  }

  &:checked + ${ToggleElement}::after {
    transform: translate3d(22px, 2px, 0);
  }
`;
const LabelElement = styled.label`
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:active ${ToggleElement}::after {
    width: 28px;
    transform: translate3d(2px, 2px, 0);
  }

  &:active ${InputElement}:checked + ${ToggleElement}::after {
    transform: translate3d(16px, 2px, 0);
  }
`;

export const Sounds = () => {
  const { activeSounds } = useSelector((e: RootStateType) => e.notification);
  const dispatch = useDispatch();
  const { t } = useTranslation('settings');

  const onChange = (isChecked: boolean) => {
    dispatch(changeSoundPreference({ activeSounds: isChecked }));
  };

  return (
    <SoundsElement>
      <span>{t('sounds.global.headline')}</span>

      <LabelElement>
        <InputElement
          type="checkbox"
          defaultChecked={activeSounds}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
        />
        <ToggleElement />
      </LabelElement>
    </SoundsElement>
  );
};
