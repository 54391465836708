import styled from 'styled-components';
import { ClosePath } from '../icons/ClosePath';

const InvalidCodeMarkerElement = styled.div`
  position: absolute;
  background-color: var(--widget-danger-color);
  width: 21px;
  height: 21px;
  right: -10.5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

export const InvalidCodeMarker = () => (
  <InvalidCodeMarkerElement>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="16"
      height="16"
    >
      <ClosePath />
    </svg>
  </InvalidCodeMarkerElement>
);
