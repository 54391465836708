import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { CallCodeAction, callCodeReducer, validateSaga } from './callcode';
import { ChatAction, chatReducer } from './chat';
import { getOptionsSaga, GlobalAction, globalReducer } from './global';
import { ModalAction, modalReducer } from './modal';
import { NotificationAction, notificationReducer } from './notification';
import { getOperatorsSaga, operatorsReducer } from './operators';
import { SettingsAction, settingsReducer } from './settings';
import { ViewAction, viewReducer } from './view';

const viewPersistedReducer = persistReducer(
  {
    key: 'livesale_view',
    storage,
    whitelist: ['persistState'],
  },
  viewReducer
);
const dateTransform = createTransform(null, (outboundState) => {
  if (typeof outboundState === 'string' && !isNaN(Date.parse(outboundState))) {
    return new Date(outboundState);
  }

  return outboundState;
});
const notificationPersistedReducer = persistReducer(
  {
    key: 'livesale_notification',
    storage,
    whitelist: [
      'reshowAfter',
      'showOrHideNotification',
      'reshowPromoImageAfter',
      'activeSounds',
    ],
    transforms: [dateTransform],
  },
  notificationReducer
);
const settingsPersistedReducer = persistReducer(
  {
    key: 'livesale_settings',
    storage,
    whitelist: ['phonePrefix'],
  },
  settingsReducer
);

const combinedReducer = combineReducers({
  global: globalReducer,
  view: viewPersistedReducer,
  notification: notificationPersistedReducer,
  callCode: callCodeReducer,
  modal: modalReducer,
  settings: settingsPersistedReducer,
  chat: chatReducer,
  operators: operatorsReducer,
});

export type RootStateType = ReturnType<typeof combinedReducer>;

export type RootActionType =
  | GlobalAction
  | ViewAction
  | NotificationAction
  | CallCodeAction
  | ModalAction
  | SettingsAction
  | ChatAction;

const rootReducer = (state: RootStateType, action: RootActionType) =>
  combinedReducer(state, action);

const persistConfig = {
  key: 'livesale_root',
  storage,
  timeout: 3600,
  whitelist: ['global'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer as any);

const sagaMiddleware = createSagaMiddleware({
  onError: (error, errorInfo) => {
    console.log(error, errorInfo);
  },
});

const middlewares = [sagaMiddleware];

const store = createStore(
  persistedReducer,
  composeWithDevTools({ trace: process.env.NODE_ENV === 'development' })(
    applyMiddleware(...middlewares)
  )
);

function* rootSaga() {
  yield all([getOptionsSaga(), validateSaga(), getOperatorsSaga()]);
}

sagaMiddleware.run(rootSaga);

export const useApplicationStore = () => {
  (window as any).liveSaleWidgetStore = store;
  const persistor = persistStore(store as any);

  return { store, persistor };
};
