import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { toggleMenu } from '../../state/settings';
import { RootStateType } from '../../state/store';
import { SettingsIcon } from '../icons/SettingsIcon';

const SettingsButtonElement = styled.button`
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background-color: transparent;
  color: var(--widget-color);
  margin-right: 5px;
`;

type Props = {
  buttonRef: React.RefObject<HTMLButtonElement>;
};

export const SettingsButton = ({ buttonRef }: Props) => {
  const {
    settings: { active },
  } = useSelector((e: RootStateType) => e);
  const dispatch = useDispatch();

  const onSettingsClick = React.useCallback(() => {
    if (!active) {
      dispatch(toggleMenu());
    }
  }, [dispatch, active]);

  return (
    <SettingsButtonElement
      ref={buttonRef}
      type="button"
      onClick={onSettingsClick}
    >
      <SettingsIcon />
    </SettingsButtonElement>
  );
};
