import styled from 'styled-components';

const PhotoWrapper = styled.div`
  position: relative;
  margin-right: var(--widget-content-padding);
`;
const PhotoElement = styled.img`
  --dimensions: 34px;

  display: block;
  width: var(--dimensions);
  height: var(--dimensions);
  border-radius: 50%;
  border: 2px solid var(--widget-color-green);
`;
const StatusDot = styled.div`
  --dimensions: 7px;

  position: absolute;
  width: var(--dimensions);
  height: var(--dimensions);
  background-color: var(--widget-color-green);
  border-radius: 50%;
  top: 5px;
  right: 0;
`;

type Props = {
  fullName: string;
  photoUrl: string;
};

export const Photo = ({ fullName, photoUrl }: Props) => (
  <PhotoWrapper>
    <PhotoElement src={photoUrl} alt={fullName} />

    <StatusDot />
  </PhotoWrapper>
);
