import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './Button';

type Props = {
  elementRef: React.RefObject<HTMLButtonElement>;
  onCloseClick: () => void;
};

export const GoBackWarningModal = ({ elementRef, onCloseClick }: Props) => {
  const { t } = useTranslation('modals');

  return (
    <div>
      <Button buttonRef={elementRef} onClick={onCloseClick}>
        {t('goBackWarning.button')}
      </Button>
    </div>
  );
};
