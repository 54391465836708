import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { hideNotification } from '../../state/notification';
import { ClosePath } from '../icons/ClosePath';

const CloseButtonElement = styled.button`
  border-width: 0;
  background-color: transparent;
  height: unset;
  width: unset;
  color: var(--widget-color);
  padding: 0;
  margin: 0;
  display: flex;
`;

export const CloseButton: React.FC = () => {
  const dispatch = useDispatch();
  const onClick = () => dispatch(hideNotification());

  return (
    <CloseButtonElement onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        aria-hidden="true"
        focusable="false"
      >
        <ClosePath />
      </svg>
    </CloseButtonElement>
  );
};
