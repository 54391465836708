import { isEmpty } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { IMaskMixin } from 'react-imask';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { setCode, validateAsync } from '../../state/callcode';
import { show } from '../../state/modal';
import { RootStateType } from '../../state/store';
import { CallEntryForm } from './CallEntryForm';
import { ErrorModalContent } from './ErrorModalContent';
import { InvalidCodeMarker } from './InvalidCodeMarker';

const CallCodeElement = styled.div`
  display: flex;
  justify-content: space-between;
  padding: calc(var(--widget-content-padding) + 5px);
`;
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Headline = styled.h3`
  font-size: 16px;
  margin: 0;
`;
const Description = styled.span<{ valid: boolean | null }>`
  --description-color: var(--widget-color-secondary);
  color: var(--description-color);

  ${({ valid }) =>
    valid === false &&
    css`
      --description-color: var(--widget-danger-color);
    `};
`;
const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

type StyledInputProps = {
  color: 'white' | 'black';
  valid: boolean | null;
};

const InternalInput: React.FC<
  StyledInputProps & { inputRef: React.Ref<HTMLInputElement> }
> = ({ inputRef, color, valid, ...rest }) => (
  <input
    ref={inputRef}
    autoComplete="off"
    type="text"
    pattern="[0-9]*"
    {...rest}
  />
);

const InputElement = styled(InternalInput)<StyledInputProps>`
  --border-color: var(--widget-input-border-color);

  color: var(--widget--color);
  text-align: center;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  font-size: 24px;
  line-height: 24px;
  padding: 0;
  height: 48px;
  width: 147px;
  background-color: transparent;

  &::placeholder {
    color: ${({ color }) =>
      color === 'white' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)'};
  }

  ${({ valid }) =>
    valid === false &&
    css`
      --border-color: var(--widget-danger-color);
    `};
`;

const MaskedInput = IMaskMixin<StyledInputProps, HTMLInputElement>(
  ({ inputRef, color, ...rest }) => (
    <InputElement inputRef={inputRef} color={color} {...rest} />
  )
);

export const CallCode = () => {
  const {
    theme: { color },
  } = useSelector((e: RootStateType) => e.global.options!);
  const {
    code,
    codeEntered,
    valid,
    validating,
    error,
    validationResponse,
  } = useSelector((e: RootStateType) => e.callCode);
  const dispatch = useDispatch();
  const { t } = useTranslation('widget');
  const submitFormButtonRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (code.length === 4) {
      dispatch(validateAsync.request(code));
    }
  }, [code, dispatch]);

  React.useEffect(() => {
    if (!isEmpty(error)) {
      dispatch(
        show({
          title: 'callcodeError.title',
          text: `callcodeError.${error}`,
          content: ({ ...props }) => {
            const _onCloseClick = props.onCloseClick;
            const onClose = () => {
              dispatch(setCode(''));
              _onCloseClick();
            };
            props.onCloseClick = onClose;

            return <ErrorModalContent {...props} />;
          },
        })
      );
    }
  }, [error, dispatch]);

  React.useEffect(() => {
    if (validationResponse) {
      submitFormButtonRef.current?.click();
    }
  }, [validationResponse]);

  return (
    <CallCodeElement>
      <TextWrapper>
        <Headline>{t('callcode.headline')}</Headline>
        <Description valid={valid}>
          {valid === null && !validating && t('callcode.description.normal')}
          {validating && t('callcode.description.validating')}
          {valid === false && t('callcode.description.error')}
        </Description>
      </TextWrapper>

      <InputWrapper>
        <MaskedInput
          value={code}
          mask="0 0 0 0"
          // blocks={{
          // 	num: { mask: IMask.MaskedNumber },
          // }}
          onAccept={(value) => {
            const rawCode = ((value as unknown) as string).replace(/ /g, '');
            dispatch(setCode(rawCode));
          }}
          placeholder="0 0 0 0"
          color={color}
          readOnly={codeEntered}
          valid={valid}
        />

        {valid === false && <InvalidCodeMarker />}
      </InputWrapper>

      {validationResponse && (
        <CallEntryForm
          validationResponse={validationResponse}
          submitFormButtonRef={submitFormButtonRef}
        />
      )}
    </CallCodeElement>
  );
};
