import styled, { css } from 'styled-components';
import { Activable, PhoneFormat } from '../../types';

const PrefixesDropdownElement = styled.div<Activable>`
  position: absolute;
  top: calc(100% + 5px);
  z-index: 10;
  display: none;
  background-color: var(--widget-content-background-color);
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  ${({ active }) =>
    active &&
    css`
      display: block;
    `};
`;
const ImageElement = styled.img`
  width: 24px;
  margin-right: 5px;
`;
const PrefixElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;

  &:not(:last-of-type) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.125);
  }
`;

type Props = Activable & {
  formats: PhoneFormat[];
  onFormatChange: (format: PhoneFormat) => void;
};

export const PrefixesDropdown = ({
  active,
  formats,
  onFormatChange,
}: Props) => (
  <PrefixesDropdownElement active={active}>
    {formats.map((format) => (
      <PrefixElement key={format.code} onClick={() => onFormatChange(format)}>
        <ImageElement src={format.imageUrl} alt="" />
        <span>{format.prefix}</span>
      </PrefixElement>
    ))}
  </PrefixesDropdownElement>
);
