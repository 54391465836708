import { Resource } from 'i18next';
import { WidgetOptions } from '../types';
import { apiGet, FetchResponse } from './http-utils';

const translations: Resource = {
  en: {
    notifications: {
      headline: 'Hello World',
      description: 'this is our fancy new widget!',
    },
    settings: {
      personalization: {
        headline: 'Personalization',
        languages: {
          headline: 'Language',
          switchTo: {
            cs: 'Switch to Czech language',
            en: 'Switch to English language',
          },
          language: {
            cs: 'Czech',
            en: 'English',
          },
        },
        phonecall: {
          headline: 'Default prefix',
        },
      },
      sounds: {
        headline: 'Sounds',
        global: {
          headline: 'Global',
          state: {
            enabled: 'Enabled',
            disabled: 'Disabled',
          },
        },
      },
      notifications: {
        headline: 'Notifications and ads',
        welcomeNotification: {
          headline: 'Welcome notification',
          options: {
            halfday: 'Hide for 12 hours',
            day: 'Hide for 24 hours',
            indefinitely: 'Hide indifinetely',
            show: 'Show',
          },
        },
      },
    },
    widget: {
      headline: 'Welcome to Apple,',
      description: 'we help business grow by connecting you to your customers.',
      operators: {
        headline: 'I need advice',
        description: "Let's connect with our experts",
        imageAlt: 'Show our experts',
      },
      promo: {
        imageAlt: 'Go to page',
      },
      callcode: {
        headline: 'Ordered Call',
        description: {
          normal: 'Enter 4-digit code',
          validating: 'Validating...',
          error: 'Invalid code',
        },
      },
      quickLinks: {
        headline: 'Quick Links',
      },
      guides: {
        help: 'I want a personal help',
      },
    },
    chat: {
      headline: 'Chat',
      description: {
        loading: 'Searching for available operator...',
      },
      loading: 'Wait a moment...',
      toCobrowsing: 'Make a phonecall',
    },
    custom_guides: {
      1: 'Would like to get exact offer',
      2: 'Submit a complaint',
    },
    message: {
      headline: 'Leave message',
      description:
        'There are currently no operators to conect with. Leave us a message and we will get back to you!',
      labels: {
        email: 'Email',
        phone: 'Phone number',
        message: 'Message',
      },
      errors: {
        email: 'Email is incomplete or invalid',
        phone: 'Phone is incomplete or invalid',
        message: 'Message contains inappropriate words',
      },
      send: 'Send',
    },
    callorder: {
      headline: 'Order a Call',
      description:
        "Leave us you phone number please. We will call you back. It'll be quicker and easier :)",
      callMe: 'Call me',
    },
    operators: {
      headline: 'Operators',
      description: 'Chose and contact operator that you like.',
      search: {
        placeholder: 'Search',
      },
      loading: 'Loading Operators. Wait a moment...',
      chat: 'Chat',
      phoneCall: 'Phone call',
      message: 'Message',
      callOrder: 'Call me',
    },
    phonecall: {
      headline: 'Call',
      description:
        'Please leave us your phone number. Operator will call you back.',
      callMe: 'Call me',
    },
    modals: {
      closeWarning: {
        title: 'Warning',
        text: 'Closing this widget will result in data loss in current form.',
        button: 'I understand, close anyway',
      },
      goBackWarning: {
        title: 'Warning',
        text: 'Going back will result in data loss in current form.',
        button: 'I understand, go back anyway',
      },
      callcodeError: {
        title: 'Error has occured',
        understood: 'Understood',
        networkError: 'Connection error has occured, please try again later.',
        timedout: 'Code is no longer valid.',
        invalid: 'Invalid code.',
      },
      openChatWarning: {
        title: 'Do you really want to close this chat?',
        text: "In case you'd need anything else simply come back.",
        button: 'Yes, close this chat',
      },
      landscape: {
        text: "Change this device's orientation to portrait.",
      },
    },
    help: {
      headline: 'Pomoc s nákupem',
      operators: 'Zvolte preferovaný způsob spojení s operátorem',
      types: {
        chat: 'Chat',
        phonecall: 'Hovor',
        videocall: 'Videohovor',
        callorder: 'Rezervace hovoru',
        message: 'Zanechte nám zprávu',
      },
    },
  },
  cs: {
    notifications: {
      headline: 'Ahoj světe',
      description: 'toto je náš líbezný nový widget!',
    },
    settings: {
      personalization: {
        headline: 'Personalizace',
        languages: {
          headline: 'Jazyk',
          switchTo: {
            cs: 'Přepnout do českého jazyka',
            en: 'Přepnout do anglického jazyka',
          },
          language: {
            cs: 'Čeština',
            en: 'Angličtina',
          },
        },
        phonecall: {
          headline: 'Výchozí předvolba',
        },
      },
      sounds: {
        headline: 'Zvuky',
        global: {
          headline: 'Globálně',
          state: {
            enabled: 'Povoleny',
            disabled: 'Zakázány',
          },
        },
      },
      notifications: {
        headline: 'Notifikace a reklamy',
        welcomeNotification: {
          headline: 'Uvítací notifikace',
          options: {
            halfday: 'Skrýt na 12 hodin',
            day: 'Skrýt na 24 hodin',
            indefinitely: 'Skrýt navždy',
            show: 'Zobrazovat',
          },
        },
      },
    },
    widget: {
      headline: 'Vítejte u Apple,',
      description:
        'pomáháme Vaší společnosti růst tím, že Vás spojíme s Vašimi zákazníky.',
      operators: {
        headline: 'Potřebuji poradit',
        description: 'Spojíme Vás s našimi experty',
        imageAlt: 'Přejít na naše experty',
      },
      promo: {
        imageAlt: 'Přejít na stránku',
      },
      callcode: {
        headline: 'Objednaný hovor',
        description: {
          normal: 'Zadejte 4místný kód',
          validating: 'Probíhá ověřování...',
          error: 'Neplatný kód',
        },
      },
      quickLinks: {
        headline: 'Rychlé odkazy',
      },
      guides: {
        help: 'Chci pomoct s nákupem',
      },
    },
    chat: {
      headline: 'Chat',
      description: {
        loading: 'Hledáme volného operátora...',
      },
      loading: 'Chviličku strpení...',
      toCobrowsing: 'Zavoláme si',
    },
    custom_guides: {
      1: 'Chci přesnou nabídku',
      2: 'Zadat reklamaci',
    },
    message: {
      headline: 'Zanechat vzkaz',
      description:
        'Momentálně se nelze spojit s žádným operátorem. Zanechte nám vzkaz, ozveme se!',
      labels: {
        email: 'Vám e-mail',
        phone: 'Váš telefon',
        message: 'Vaše zpráva',
      },
      errors: {
        email: 'E-mail není kompletní nebo je neplatný',
        phone: 'Telefon není kompletní nebo je neplatný',
        message: 'Zpráva obsahuje nevhodná slova',
      },
      send: 'Odeslat',
    },
    callorder: {
      headline: 'Rezervovat hovor',
      description:
        'Zadejte prosím vaše telefonní číslo. Ozvu se Vám zpět. Bude to pohodlnější a rychlejší :)',
      callMe: 'Zavolejte mi',
    },
    operators: {
      headline: 'Operátoři',
      description: 'Vyberte si a kontaktujte libovolného operátora.',
      search: {
        placeholder: 'Hledat',
      },
      loading: 'Načítání operátorů. Chviličku strpení...',
      chat: 'Chat',
      phoneCall: 'Hovor',
      message: 'Zpráva',
      callOrder: 'Zavolejte mi',
    },
    phonecall: {
      headline: 'Hovor',
      description:
        'Zadejte prosím vaše telefonní číslo. Operátor Vám zavolá zpět.',
      callMe: 'Zavolejte mi',
    },
    modals: {
      closeWarning: {
        title: 'Pozor',
        text: 'Chystáte se zvařít widget. Tímto dojde ke ztrátě zadaných dat.',
        button: 'Rozumím, přesto zavřít',
      },
      goBackWarning: {
        title: 'Pozor',
        text: 'Chystáte se jít zpět. Tímto dojde ke ztrátě zadaných dat.',
        button: 'Rozumím, jít zpět',
      },
      callcodeError: {
        title: 'Nastal problém',
        understood: 'Rozumím',
        networkError: 'Nastal problém s připojením, opakujte akci později.',
        timedout: 'Platnost kódu vypršela.',
        invalid: 'Neplatný kód.',
      },
      openChatWarning: {
        title: 'Opravdu ukončit chat?',
        text:
          'Kdyby Vás cokoliv napadlo, je možné se k chatu jednoduše vrátit.',
        button: 'Ano, chci ukončit chat',
      },
      landscape: {
        text: 'Otočte zařízení zpět na výšku.',
      },
    },
    help: {
      headline: 'Pomoc s nákupem',
      operators: 'Zvolte preferovaný způsob spojení s operátorem',
      types: {
        chat: 'Chat',
        phonecall: 'Hovor',
        videocall: 'Videohovor',
        callorder: 'Rezervace hovoru',
        message: 'Zanechte nám zprávu',
      },
    },
  },
};

// flags taken from https://www.flaticon.com/packs/international-flags?k=1615799008618
export const getOptions = async (): Promise<FetchResponse<WidgetOptions>> => {
  const url = `${process.env.REACT_APP_NAPI_URL}/v3/widget/get/5e9c19e790f77504fb8f274b`;
  const options = await apiGet<WidgetOptions>(url);

  if (!options.data.activeSections) {
    options.data.activeSections = [];
  }
  options.data.activeSections.push('guides');
  if (options.data.callCodePanel.isEnabled) {
    options.data.activeSections.push('callcode');
  }
  if (options.data.promoImagePanel.isEnabled) {
    options.data.activeSections.push('promoimage');
  }
  if (options.data.hyperlinksPanel.isEnabled) {
    options.data.activeSections.push('hyperlinks');
  }

  options.data.l10n.translations = translations;

  options.data.i18n.phoneNumberOptions.blacklist = [
    '123456789',
    '987654321',
    '111222333',
    '999888777',
    '666555444',
    '333222111',
  ];
  options.data.callOrder = {
    emailBlacklist: ['test'],
    messageBlacklist: ['vole', 'vůl'],
  };

  return options;
};
