import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootStateType } from '../../state/store';
import { Settings } from '../settings/Settings';
import { SettingsButton } from './SettingsButton';

const ActionsElement = styled.div`
  height: 24px;
  width: calc(var(--widget-width) - 2 * var(--widget-content-padding) - 24px);
  display: flex;
  position: relative;
`;

const SeparatorElement = styled.div`
  flex-grow: 1;
`;

export const Actions: React.FC = () => {
  const {
    settings: { active },
  } = useSelector((e: RootStateType) => e);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  return (
    <ActionsElement>
      <SeparatorElement />

      <SettingsButton buttonRef={buttonRef} />

      {active && <Settings openButtonRef={buttonRef} />}
    </ActionsElement>
  );
};
