import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';

const loadingKeyframes = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;
const LoaderElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;
const LineLoaderElement = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 50%;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    z-index: 10;
    background: var(--widget-content-background-color);
    border-radius: 50%;
  }

  span {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: linear-gradient(
      -225deg,
      var(--widget-content-background-color) 0%,
      var(--widget-content-background-color) 15%,
      #34dd34 75%,
      #34dd34 100%
    );

    z-index: -1;
    animation: ${loadingKeyframes} 2s linear infinite;
  }
`;
const DescriptionElement = styled.span`
  color: var(--widget-color-green);
  margin-top: 10px;
`;

export const Loader = () => {
  const { t } = useTranslation('operators');

  return (
    <LoaderElement>
      <LineLoaderElement>
        <span />
      </LineLoaderElement>

      <DescriptionElement>{t('loading')}</DescriptionElement>
    </LoaderElement>
  );
};
