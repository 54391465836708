import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { sendMessage } from '../../state/chat';
import { PaperclipIcon } from '../icons/PaperclipIcon';
import { SendIcon } from '../icons/SendIcon';

const InputElement = styled.input`
  margin: 0;
  padding: 0;
  padding-left: 10px;
  height: 100%;
  flex-grow: 1;
  border-width: 0;
  font-size: 16px;
  background-color: transparent;
  color: var(--widget-color);

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
  }
`;
const AttachmentButtonElement = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  color: var(--widget-color-secondary);
  width: 30px;

  &:not(:disabled):active svg {
    transform: scale(0.9);
  }
`;
const SendButtonElement = styled.button`
  padding: 0;
  margin: 0;
  margin-right: var(--widget-content-padding);
  border: none;
  background-color: transparent;
  color: var(--widget-color-green);
  width: 40px;

  &:not(:disabled):active svg {
    transform: scale(0.9);
  }

  &:disabled {
    color: gray;
  }
`;

export const Input: React.FC = () => {
  const dispatch = useDispatch();

  const [value, setValue] = React.useState<string>('');
  const [sendDisabled, setSendDisabled] = React.useState(true);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const maybeSendMessage = React.useCallback(
    (content: string): boolean => {
      if (content.trim().length !== 0) {
        dispatch(sendMessage({ sender: 'user', message: content }));

        return true;
      }

      return false;
    },
    [dispatch]
  );
  const onKeyUp = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      setSendDisabled(!(value.length > 0));

      if (e.key === 'Enter' && !sendDisabled) {
        if (maybeSendMessage(value)) {
          setValue('');
          setSendDisabled(true);
        }
      }
    },
    [value, sendDisabled, maybeSendMessage]
  );
  const onSendClick = React.useCallback(() => {
    if (inputRef?.current) {
      if (maybeSendMessage(value)) {
        setValue('');
        setSendDisabled(true);
      }
    }
  }, [inputRef, value, maybeSendMessage]);

  return (
    <>
      <InputElement
        ref={inputRef}
        type="text"
        placeholder="Zde můžete psát"
        onKeyUp={onKeyUp}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />

      <AttachmentButtonElement type="button">
        <PaperclipIcon width={20} height={20} />
      </AttachmentButtonElement>

      <SendButtonElement
        type="button"
        disabled={sendDisabled}
        onClick={onSendClick}
      >
        <SendIcon />
      </SendButtonElement>
    </>
  );
};
