import * as React from 'react';
import styled from 'styled-components';

const TextElement = styled.div`
  margin-bottom: 20px;
`;

type Props = {
  className?: string;
};

export const Text: React.FC<Props> = ({ className, children }) => (
  <TextElement className={className}>{children}</TextElement>
);
