import { isEmpty } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { setOperator } from '../../state/chat';
import { RootStateType } from '../../state/store';
import { setCanClose } from '../../state/view';
import { Actions } from '../common/Actions';
import { Interactions } from './Interactions';
import { MessagesDisplay } from './MessagesDisplay';
import { Operator } from './operator/Operator';

const ChatElement = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--widget-content-padding);
  padding-top: 0;
`;
const HeadlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: var(--widget-content-padding) 0;
  margin-bottom: var(--widget-subcontent-padding);
`;
const ButtonElement = styled.button`
  padding: 0;
  margin: 0;
  border-radius: 8px;
  background-color: var(--widget-color-green);
  width: 140px;
  height: 48px;
  border: none;
  font-size: 14px;
  color: white;

  &:disabled {
    background-color: rgba(var(--widget-color-green-rgb), 0.3);
  }
`;

export const Chat: React.FC = () => {
  const { loading, operator } = useSelector((e: RootStateType) => e.chat);
  const dispatch = useDispatch();
  const { t } = useTranslation('chat');
  const isMobile = useMediaQuery({
    query: 'only screen and (max-width: 599px)',
  });

  React.useEffect(() => {
    if (!loading && !isEmpty(operator)) {
      dispatch(setCanClose(false));
    }
  }, [operator, loading, dispatch]);

  React.useEffect(() => {
    if (isEmpty(operator)) {
      setTimeout(() => {
        dispatch(
          setOperator({
            operator: {
              operatorId: '6308dfd1-ed8b-4e1f-9aec-e7545a7d7ae3',
              fullName: 'Jonathan Ive',
              tag: 'specialista telefony',
              status: 'online',
              photoUrl:
                'https://demo.livesale.me/Photos/petr_macik_livesale_cz-85x85px.jpg',
            },
          })
        );
      }, 5000);
    }
  }, [operator, dispatch]);

  const getLoadingComponent = () => <span>{t('description.loading')}</span>;
  const getOperatorComponent = () => <Operator operator={operator!} />;

  return (
    <ChatElement>
      <Actions headline={t('headline')} />

      <HeadlineWrapper>
        {loading && getLoadingComponent()}
        {!loading && getOperatorComponent()}

        {!isMobile && (
          <ButtonElement disabled={loading}>{t('toCobrowsing')}</ButtonElement>
        )}
      </HeadlineWrapper>

      <MessagesDisplay />

      <Interactions />
    </ChatElement>
  );
};
