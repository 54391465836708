import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { hide } from '../../state/modal';
import { setCanClose, setForceClose } from '../../state/view';
import { Button } from './Button';

type Props = {
  elementRef: React.RefObject<HTMLButtonElement>;
};

export const CloseWarningModal = ({ elementRef }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('modals');

  const onClick = () => {
    dispatch(hide());
    dispatch(setCanClose(true));
    dispatch(setForceClose(true));
  };

  return (
    <div>
      <Button buttonRef={elementRef} onClick={onClick}>
        {t('closeWarning.button')}
      </Button>
    </div>
  );
};
