import styled from 'styled-components';

const ErrorMessageElement = styled.span`
  position: absolute;
  top: 100%;

  color: var(--widget-danger-color);
  font-size: 11px;
`;

export const ErrorMessage: React.FC = ({ children }) => (
  <ErrorMessageElement>{children}</ErrorMessageElement>
);
