import * as React from 'react';
import styled from 'styled-components';

const HyperlinkElement = styled.span`
  background-color: #cef6ce;
  margin: 0 10px 10px 0;
  padding: 4px 8px;
  border-radius: 4px;
  color: #212121;
  text-decoration: none;

  cursor: pointer;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='11' height='11'%3E%3Cpath d='M10 6v2H5v11h11v-5h2v6a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h6zm11-3v8h-2V6.413l-7.793 7.794-1.414-1.414L17.585 5H13V3h8z'/%3E%3C/svg%3E");
  background-position: center right 3px;
  background-repeat: no-repeat;
  padding-right: 16px;

  &:active {
    color: #e95b26;
  }
`;

type Props = {
  onClick: () => void;
};

export const Hyperlink: React.FC<Props> = ({ onClick, children }) => (
  <HyperlinkElement onClick={onClick}>{children}</HyperlinkElement>
);
