import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setCanClose } from '../../state/view';
import { Actions } from '../common/Actions';
import { NumberChanges } from '../common/numberchanges';
import { PhoneNumberInput } from '../common/PhoneNumberInput';
import { EmailInput } from './EmailInput';
import { EmailChanges, MessageChanges } from './inputchanges';
import { MessageInput } from './MessageInput';
import { SectionInfo } from './SectionInfo';

const MessageElement = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--widget-content-padding);
  padding-top: 0;
`;
const MessageSectionElement = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--widget-content-background-color);
  margin: calc(-2 * var(--widget-content-padding));
  padding: calc(2 * var(--widget-content-padding));
  margin-top: 0;
  border-radius: 10px;
  overflow: auto;
`;
const LabelElement = styled.label`
  font-size: 14px;
  margin-bottom: 10px;

  &::after {
    content: ' *';
    color: var(--widget-danger-color);
  }
`;
const ButtonElement = styled.button`
  padding: 0;
  margin: 0;
  border-radius: 8px;
  background-color: var(--widget-color-green);
  width: 100%;
  height: 48px;
  border: none;
  font-size: 14px;
  color: white;

  &:disabled {
    background-color: var(--widget-background-color);
    color: var(--widget-input-border-color);
  }
`;

export const Message = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('message');
  const [validInputs, setValidInputs] = React.useState({
    phone: false,
    email: false,
    message: false,
  });
  const [valuedInputs, setValuedInputs] = React.useState({
    phone: true,
    email: true,
    message: true,
  });
  const [disabled, setDisabled] = React.useState(true);

  const setOwnValid = ({
    valid,
    key,
  }: {
    valid: boolean;
    key: 'phone' | 'email' | 'message';
  }) => {
    setValidInputs((s) => {
      s[key] = valid;

      return { ...s };
    });
  };
  const setOwnValuedInput = ({
    hasValue,
    key,
  }: {
    hasValue: boolean;
    key: 'phone' | 'email' | 'message';
  }) => {
    setValuedInputs((s) => {
      s[key] = hasValue;

      return { ...s };
    });
  };
  const setEmailValid = React.useCallback(
    ({ valid, address }: EmailChanges) => {
      setOwnValid({ valid, key: 'email' });
      setOwnValuedInput({ hasValue: isEmpty(address), key: 'email' });
    },
    []
  );
  const setPhoneValid = React.useCallback(
    ({ valid, phoneNumber }: NumberChanges) => {
      setOwnValid({ valid, key: 'phone' });
      setOwnValuedInput({
        hasValue: isEmpty(phoneNumber.number),
        key: 'phone',
      });
    },
    []
  );
  const setMessageValid = React.useCallback(
    ({ valid, message }: MessageChanges) => {
      setOwnValid({ valid, key: 'message' });
      setOwnValuedInput({ hasValue: isEmpty(message), key: 'message' });
    },
    []
  );

  React.useEffect(() => {
    const keys = Object.keys(validInputs);
    const keysAreValid = keys.every((key) => {
      const k = key as 'phone' | 'email' | 'message';

      return validInputs[k];
    });

    setDisabled(!keysAreValid);
  }, [validInputs]);

  React.useEffect(() => {
    const keys = Object.keys(valuedInputs);
    const showWarningModal = keys.some((key) => {
      const k = key as 'phone' | 'email' | 'message';

      return !valuedInputs[k];
    });

    if (showWarningModal) {
      dispatch(setCanClose(false));
    } else {
      dispatch(setCanClose(true));
    }
  }, [valuedInputs, dispatch]);

  return (
    <MessageElement>
      <Actions headline={t('headline')} />

      <SectionInfo />

      <MessageSectionElement>
        <LabelElement htmlFor="email">{t('labels.email')}</LabelElement>
        <EmailInput onChange={setEmailValid} errorMessage={t('errors.email')} />

        <LabelElement htmlFor="phone">{t('labels.phone')}</LabelElement>
        <PhoneNumberInput
          onChange={setPhoneValid}
          errorMessage={t('errors.phone')}
        />

        <LabelElement htmlFor="message">{t('labels.message')}</LabelElement>
        <MessageInput
          onChange={setMessageValid}
          errorMessage={t('errors.message')}
        />

        <ButtonElement type="button" disabled={disabled}>
          {t('send')}
        </ButtonElement>
      </MessageSectionElement>
    </MessageElement>
  );
};
