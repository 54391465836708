import {} from './common';
import { apiPost, FetchResponse } from './http-utils';
import { ROUTES } from './routes';

type ValidateProps = {
  password: string;
};

export type ValidPasswordResponse = {
  IsValid: true;
  OriginUrl: string;
  CompanyContactID?: number;
  OperatorID: number;
  CallType: number;
};

export type InvalidPasswordResponse = { IsValid: false; ErrorMessage: string };

export const validate = (
  payload: ValidateProps
): Promise<FetchResponse<ValidPasswordResponse | InvalidPasswordResponse>> => {
  if (JSON.parse(process.env.REACT_APP_USE_REAL_INTEGRATION!) as boolean) {
    return apiPost(
      `${process.env.REACT_APP_API_URL}${ROUTES.VerifyPassword_POST}`,
      payload
    );
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      if (payload.password === '1234') {
        resolve({
          data: {
            IsValid: true,
            OriginUrl: window.location.href,
            CompanyContactID: undefined,
            OperatorID: 1,
            CallType: 2,
          },
          status: 200,
        });
      } else {
        resolve({
          data: {
            IsValid: false,
            ErrorMessage: Math.random() > 0.5 ? 'timedout' : 'invalid',
          },
          status: 400,
        });
      }
    }, 5000);
  });
};
