import { ValidPasswordResponse } from '../../api/callcode';

type Props = {
  validationResponse: ValidPasswordResponse;
  submitFormButtonRef: React.RefObject<HTMLButtonElement>;
};

export const CallEntryForm = ({
  validationResponse,
  submitFormButtonRef,
}: Props) => (
  <form
    action={`${process.env.REACT_APP_API_URL}/Client/SelectWay`}
    method="post"
    noValidate={true}
    style={{ display: 'none' }}
  >
    <input
      id="OperatorID"
      name="OperatorID"
      type="hidden"
      value={validationResponse.OperatorID}
    />
    <input
      id="CallType"
      name="CallType"
      type="hidden"
      value={validationResponse.CallType}
    />
    <input id="CallDirection" name="CallDirection" type="hidden" value="In" />
    <input
      id="CompanyContactID"
      name="CompanyContactID"
      type="hidden"
      value={validationResponse.CompanyContactID ?? ''}
    />
    <input
      id="CurrentUrl"
      name="CurrentUrl"
      type="hidden"
      value={validationResponse.OriginUrl}
    />
    <input id="OrderedCall" name="OrderedCall" type="hidden" value="True" />
    <input id="EntryType" name="EntryType" type="hidden" value="Widget" />
    <input
      id="PhoneNumber"
      name="PhoneNumber"
      type="hidden"
      value="000000000"
    />
    <input id="PhonePrefix" name="PhonePrefix" type="hidden" value="+420" />

    <button ref={submitFormButtonRef} type="submit" />
  </form>
);
