import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootStateType } from '../../state/store';
import { Language as LanguageType } from '../../types';
import { Dropdown, DropdownItem } from './common/Dropdown';
import { SettingsItem } from './styles/SettingsItem.styles';

const CurrentLanguageElement = styled.div`
  ${SettingsItem};
`;
const FlagImageElement = styled.img`
  margin-right: 5px;
`;

export const Language = () => {
  const {
    global: { options },
  } = useSelector((e: RootStateType) => e);
  const [supportedLanguages] = React.useState(options!.l10n.locales);
  const { t, i18n } = useTranslation('settings');
  const [currentLanguage, setCurrentLanguage] = React.useState<LanguageType>(
    supportedLanguages.find((lang) => lang.code === i18n.language)!
  );
  const onLanguageClick = (language: LanguageType) => {
    setCurrentLanguage(language);
    i18n.changeLanguage(language.code);
  };

  return (
    <CurrentLanguageElement>
      <span>{t('personalization.languages.headline')}</span>

      <Dropdown
        placeholder={
          <Placeholder
            imageUrl={currentLanguage.imageUrl}
            imageAlt={t(
              `personalization.languages.language.${currentLanguage.code}`
            )}
            text={t(
              `personalization.languages.language.${currentLanguage.code}`
            )}
          />
        }
      >
        {(onClose) =>
          supportedLanguages.map((language) => (
            <DropdownItem
              key={language.code}
              onClick={() => {
                onLanguageClick(language);
                onClose();
              }}
            >
              <Placeholder
                imageUrl={language.imageUrl}
                imageAlt={t(
                  `personalization.languages.switchTo.${language.code}`
                )}
                text={t(`personalization.languages.language.${language.code}`)}
              />
            </DropdownItem>
          ))
        }
      </Dropdown>
    </CurrentLanguageElement>
  );
};

type PlaceholderProps = { imageUrl: string; imageAlt: string; text: string };
const Placeholder = ({ imageUrl, imageAlt, text }: PlaceholderProps) => (
  <>
    <FlagImageElement src={imageUrl} alt={imageAlt} />

    <span>{text}</span>
  </>
);
