/* eslint-disable no-bitwise */
/* eslint-disable no-nested-ternary */
import { Operator } from '../types';
import { FetchResponse } from './common';

const _firstNames = {
  female: ['Marie', 'Jana', 'Eva', 'Anna', 'Hana', 'Věra'],
  male: ['Jiří', 'Jan', 'Petr', 'Pavel', 'Jaroslav', 'Michal'],
};
const _lastNames = {
  female: [
    'Nováková',
    'Procházková',
    'Němcová',
    'Veselá',
    'Černá',
    'Dvořáková',
  ],
  male: ['Pospíšil', 'Marek', 'Král', 'Hájek', 'Jelínek', 'Lin'],
};
const getName = () => {
  const random = Math.random();
  const sex =
    random > 0.75
      ? 'male'
      : random > 0.5
      ? 'female'
      : random > 0.25
      ? 'male'
      : 'female';

  return `${_firstNames[sex][Math.floor(Math.random() * 6)]} ${
    _lastNames[sex][Math.floor(Math.random() * 6)]
  }`;
};

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const getOperators = (): Promise<FetchResponse<Operator[]>> =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        json: Array.from({ length: 20 }, () => ({
          operatorId: uuidv4(),
          fullName: getName(),
          tag: 'specialista telefony, tablety',
          status: Math.random() > 0.5 ? 'online' : 'offline',
          photoUrl:
            'https://demo.livesale.me/Photos/petr_macik_livesale_cz-85x85px.jpg',
        })),
        status: 200,
      });
    }, 0);
  });
