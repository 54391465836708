import React from 'react';
import { useDispatch } from 'react-redux';
import { changeContent } from '../../../state/view';
import { Status } from '../../../types';
import { ChatPath } from '../../icons/ChatPath';
import { BubbleElement, TextElement, WrapperElement } from './Common';

type Props = {
  status: Status;
  operatorId: string;
};

export const ChatBubble = ({ operatorId, status }: Props) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(
      changeContent({
        direction: 'forward',
        content: 'chat',
        viewData: { operatorId },
      })
    );
  };

  return (
    <WrapperElement onClick={onClick} active={status === 'online'}>
      <BubbleElement active={status === 'online'}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <ChatPath />
        </svg>
      </BubbleElement>

      <TextElement>Chat</TextElement>
    </WrapperElement>
  );
};
