import { ActionType, createAction, createReducer } from 'typesafe-actions';

export type ModalState = {
  visible: boolean;
  title: string | null;
  text: string | null;
  content: React.ComponentType<{
    elementRef: React.RefObject<HTMLButtonElement>;
    onCloseClick: () => void;
  }> | null;
  position: 'top' | 'bottom';
  showLandscapeWarning: boolean;
};

type ShowModalProps = {
  title: string;
  text: string;
  content?: React.ComponentType<{
    elementRef: React.RefObject<HTMLButtonElement>;
    onCloseClick: () => void;
  }>;
};

export const show = createAction('@modal/SHOW')<ShowModalProps>();
export const hide = createAction('@modal/HIDE')();
export const showWarningModal = createAction(
  '@modal/SHOW_WARNING'
)<ShowModalProps>();
export const showLandscapeWarning = createAction(
  '@modal/SHOW_LANDSCAPE_WARNING'
)();
export const hideLandscapeWarning = createAction(
  '@modal/HIDE_LANDSCAPE_WARNING'
)();

export type ModalAction =
  | ActionType<typeof show>
  | ActionType<typeof hide>
  | ActionType<typeof showWarningModal>
  | ActionType<typeof showLandscapeWarning>
  | ActionType<typeof hideLandscapeWarning>;

export const modalReducer = createReducer<ModalState, ModalAction>({
  visible: false,
  title: null,
  text: null,
  content: null,
  position: 'bottom',
  showLandscapeWarning: false,
})
  .handleAction(show, (state, action) => ({
    ...state,
    visible: true,
    ...action.payload,
  }))
  .handleAction(hide, (state) => ({
    ...state,
    visible: false,
    title: null,
    text: null,
    content: null,
    position: 'bottom',
  }))
  .handleAction(showWarningModal, (state, action) => ({
    ...state,
    visible: true,
    position: 'top',
    ...action.payload,
  }))
  .handleAction(showLandscapeWarning, (state) => ({
    ...state,
    showLandscapeWarning: true,
  }))
  .handleAction(hideLandscapeWarning, (state) => ({
    ...state,
    showLandscapeWarning: false,
  }));
