import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { changeContent } from '../../state/view';

const OperatorsElement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: var(--widget-subcontent-padding);
`;
const DescriptionElement = styled.span`
  color: var(--widget-color-secondary);
  font-size: 15px;
`;
const ImagesWrapper = styled.div`
  display: flex;
`;
const ImageElement = styled.img`
  --dimensions: 42px;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 50%;

  min-width: var(--dimensions);
  max-width: var(--dimensions);
  min-height: var(--dimensions);
  max-height: var(--dimensions);

  &:first-of-type {
    margin: 0 -10px;
    z-index: 5;
  }

  &:last-of-type {
    z-index: 4;
  }
`;

export const Operators = () => {
  const { t } = useTranslation('help');
  const dispatch = useDispatch();

  const onClick = () =>
    dispatch(changeContent({ direction: 'forward', content: 'operators' }));

  return (
    <OperatorsElement>
      <DescriptionElement>{t('operators')}</DescriptionElement>

      <ImagesWrapper onClick={onClick}>
        <ImageElement
          src="/img/main/operator_male.png"
          srcSet="/img/main/operator_male.png, /img/main/operator_male@2x.png 2x"
          alt={t('operators.imageAlt')}
        />
        <ImageElement
          src="/img/main/operator_female.png"
          srcSet="/img/main/operator_female.png, /img/main/operator_female@2x.png 2x"
          alt={t('operators.imageAlt')}
        />
      </ImagesWrapper>
    </OperatorsElement>
  );
};
