import * as React from 'react';

// Usage example:
//
// const ref = useRef();
// const [isModalOpen, setModalOpen] = useState(false);
// useOnClickOutside(ref, () => setModalOpen(false));

export const useOnClickOutside = (
  ref: React.RefObject<Element>,
  handler: (event: Event) => void
) => {
  React.useEffect(() => {
    const listener = (event: Event) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};
