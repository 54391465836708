import React from 'react';
import { useDispatch } from 'react-redux';
import { changeContent } from '../../../state/view';
import { Status } from '../../../types';
import { MessageIcon } from '../../icons/MessageIcon';
import { BubbleElement, TextElement, WrapperElement } from './Common';

type Props = {
  status: Status;
  operatorId: string;
};

export const MessageBubble = ({ operatorId, status }: Props) => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(
      changeContent({
        direction: 'forward',
        content: 'message',
        viewData: { operatorId },
      })
    );
  };

  return (
    <WrapperElement onClick={onClick} active={status === 'online'}>
      <BubbleElement active={status === 'online'}>
        <MessageIcon />
      </BubbleElement>

      <TextElement>Zpráva</TextElement>
    </WrapperElement>
  );
};
