import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setCanClose } from '../../state/view';
import { Actions } from '../common/Actions';
import { NumberChanges } from '../common/numberchanges';
import { PhoneNumberInput } from '../common/PhoneNumberInput';
import { SectionInfo } from './SectionInfo';

const PhoneCallElement = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--widget-content-padding);
  padding-top: 0;
`;
const CallSectionElement = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--widget-content-background-color);
  margin: calc(-2 * var(--widget-content-padding));
  padding: calc(2 * var(--widget-content-padding));
  margin-top: 0;
  border-radius: 10px;
`;
const ButtonElement = styled.button`
  padding: 0;
  margin: 0;
  border-radius: 8px;
  background-color: var(--widget-color-green);
  width: 100%;
  height: 48px;
  border: none;
  font-size: 14px;
  color: white;

  &:disabled {
    background-color: var(--widget-background-color);
    color: var(--widget-input-border-color);
  }
`;

export const PhoneCall = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('phonecall');
  const [disabled, setDisabled] = React.useState(true);
  const [hasValue, setHasValue] = React.useState(false);

  const onChange = (changes: NumberChanges) => {
    setDisabled(!changes.valid);
    setHasValue(changes.phoneNumber.number.length > 0);
  };

  React.useEffect(() => {
    if (hasValue) {
      dispatch(setCanClose(false));
    } else {
      dispatch(setCanClose(true));
    }
  }, [dispatch, hasValue]);

  return (
    <PhoneCallElement>
      <Actions headline={t('headline')} />

      <SectionInfo />

      <CallSectionElement>
        <PhoneNumberInput
          onChange={onChange}
          errorMessage={t('message:errors.phone')}
        />

        <ButtonElement type="button" disabled={disabled}>
          {t('callMe')}
        </ButtonElement>
      </CallSectionElement>
    </PhoneCallElement>
  );
};
