import { css } from 'styled-components';

export const SettingsItem = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  min-height: 36px;

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }
`;
