import * as React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { RootStateType } from '../../state/store';
import { Section } from '../../types';
import { CallCode } from '../callcodes/CallCode';
import { Guides } from '../guides/Guides';
import { Hyperlinks } from '../hyperlinks/Hyperlinks';
import { PromoImage } from '../promoimages/PromoImage';
import { WelcomeMessage } from './WelcomeMessage';

const MainViewElement = styled.div`
  display: flex;
  flex-direction: column;
`;

type ItemElementProps = {
  useTransition: boolean;
  useBoxShadow: boolean;
  delay: number;
  active: boolean;
};

const InternalItemElement: React.FC<
  ItemElementProps & { className?: string }
> = ({
  children,
  className,
  delay,
  active,
  useTransition,
  useBoxShadow,
  ...rest
}) => (
  <div {...rest} className={className}>
    {children}
  </div>
);

const ItemElement = styled(InternalItemElement)<ItemElementProps>`
  opacity: 0;

  background-color: var(--widget-content-background-color);
  border-radius: 4px;

  ${({ useTransition, delay }) =>
    useTransition &&
    css`
      transition: opacity 1s ease;
      transition-delay: ${() => `${delay}s`};
    `}

  ${({ useBoxShadow }) =>
    useBoxShadow
      ? css`
          box-shadow: 0px 2px 0px var(--widget-color-green);
          margin-bottom: 12px;

          &:last-of-type {
            margin-bottom: 2px;
          }
        `
      : css`
          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }
        `}

	${({ active }) =>
    active &&
    css`
      opacity: 1;
    `}
`;

const _componentToTypes: Record<Section, JSX.Element> = {
  guides: <Guides />,
  callcode: <CallCode />,
  promoimage: <PromoImage />,
  hyperlinks: <Hyperlinks />,
};

export const MainView: React.FC = React.memo(() => {
  const { activeSections } = useSelector(
    (e: RootStateType) => e.global.options!
  );
  const { contentActive, loadedWithPersistedState } = useSelector(
    (e: RootStateType) => e.view
  );

  const [areItemsActive, setAreItemsActive] = React.useState(false);

  React.useEffect(() => {
    if (contentActive) {
      setAreItemsActive(true);
    } else {
      setAreItemsActive(false);
    }
  }, [contentActive, areItemsActive]);

  const getItem = (name: Section) => _componentToTypes[name];

  return (
    <MainViewElement>
      <WelcomeMessage />

      {activeSections.map((activeSection, index) => (
        <ItemElement
          key={index}
          delay={0.5 + index * 0.25}
          active={areItemsActive}
          useTransition={!loadedWithPersistedState}
          useBoxShadow={['callcode', 'hyperlinks'].includes(activeSection)}
        >
          {getItem(activeSection)}
        </ItemElement>
      ))}
    </MainViewElement>
  );
});
