import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { hide } from '../../state/modal';
import { RootStateType } from '../../state/store';
import { Activable } from '../../types';
import { CloseButton } from './CloseButton';
import { Text } from './Text';
import { Title } from './Title';

type ModalElementProps = {
  visible: boolean;
  position: 'top' | 'bottom';
};
type ContentElementProps = Activable & { position: 'top' | 'bottom' };

const InternalModal: React.FC<ModalElementProps> = ({
  children,
  visible,
  position,
  ...rest
}) => <div {...rest}>{children}</div>;

const ContainerElement = styled.div<ContentElementProps>`
  background-color: var(--widget-content-background-color);
  border-radius: 8px;
  padding: var(--widget-content-padding);
  margin: calc(-1 * var(--widget-content-padding));

  transition: transform 0.3s ease-out;
  will-change: transform;
  transform: translate(
    0,
    ${({ position }) => (position === 'bottom' ? '50px' : '-50px')}
  );

  ${({ active }) =>
    active &&
    css`
      transform: none;
    `};
`;
const ModalElement = styled(InternalModal)`
  z-index: 100;

  position: absolute;
  bottom: 0;

  display: none;
  flex-direction: column-reverse;

  padding: var(--widget-content-padding);
  width: var(--widget-width);
  color: var(--widget-color);
  background-color: rgba(243, 243, 244, 0.8);
  height: 0;
  border-radius: 8px;

  ${({ position }) =>
    position === 'bottom'
      ? css`
          justify-content: end;
        `
      : css`
          justify-content: start;
        `}

  ${({ visible }) =>
    visible &&
    css`
      display: flex;
      height: 100%;
    `}
`;

export const Modal: React.FC = () => {
  const { visible, title, text, content, position } = useSelector(
    (e: RootStateType) => e.modal
  );
  const dispatch = useDispatch();
  const { t } = useTranslation('modals');

  const [didFocus, setDidFocus] = React.useState(false);
  const [showContent, setShowContent] = React.useState(false);

  const onCloseClick = () => {
    dispatch(hide());
  };
  const elementRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (visible && elementRef?.current && !didFocus) {
      elementRef.current?.focus();
      setDidFocus(true);
    }

    if (!visible) {
      setDidFocus(false);
    }
  }, [visible, elementRef, didFocus]);

  React.useEffect(() => {
    window.setTimeout(() => setShowContent(visible), 10);
  }, [visible]);

  return (
    <ModalElement visible={visible} position={position}>
      <ContainerElement active={showContent} position={position}>
        <CloseButton onClick={onCloseClick} />

        {title && <Title>{t(title)}</Title>}

        {text && <Text>{t(text)}</Text>}

        {content &&
          React.createElement(content, {
            elementRef,
            onCloseClick,
          })}
      </ContainerElement>
    </ModalElement>
  );
};
