import * as React from 'react';
import styled from 'styled-components';

const TitleElement = styled.h3`
  margin: 0;
  margin-bottom: 10px;
  font-size: 18px;
`;

export const Title: React.FC = ({ children }) => (
  <TitleElement>{children}</TitleElement>
);
