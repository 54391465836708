import * as React from 'react';
import styled from 'styled-components';

const ButtonElement = styled.button`
  padding: 0;
  margin: 0;
  border-radius: 8px;
  background-color: var(--widget-color-green);
  width: 100%;
  height: 48px;
  border: none;
  font-size: 14px;
  color: white;
`;

type Props = {
  onClick: () => void;
  buttonRef?: React.RefObject<HTMLButtonElement>;
};

export const Button: React.FC<Props> = ({ onClick, buttonRef, children }) => (
  <ButtonElement type="button" ref={buttonRef} onClick={onClick}>
    {children}
  </ButtonElement>
);
