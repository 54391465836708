const _defaultHeaders = new Headers({
  Accept: 'application/json, application/xml, text/plain, text/html, *.*',
  'Content-Type': 'application/json',
});

export type FetchResponse<T> = {
  data: T;
  status: number;
};

async function fetchJson<T>(...args: any): Promise<FetchResponse<T>> {
  const res: Response = await (fetch as any)(...args);
  const json = await res.json();

  return { data: json, status: res.status };
}

export function apiPost<TRequest, TResponse>(
  url: string,
  request: TRequest,
  headers = _defaultHeaders
) {
  const requestOptions = {
    method: 'POST',
    headers,
    body: JSON.stringify(request),
    redirect: 'follow',
  };

  return fetchJson<TResponse>(url, requestOptions as any);
}

type ParamsObject = {
  [key: string]: any;
};

export function apiGet<TResponse>(
  url: string,
  paramsObject: ParamsObject = {},
  headers: Headers = _defaultHeaders
) {
  const queryString = Object.entries(paramsObject)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');

  return fetchJson<TResponse>(`${url}?${queryString}`);
}

export function apiPut<TResponse, TRequest>(
  url: string,
  request: TRequest,
  headers: Headers = _defaultHeaders
) {
  const requestOptions = {
    method: 'PUT',
    headers,
    body: JSON.stringify(request),
    redirect: 'follow',
  };

  return fetchJson<TResponse>(url, requestOptions as any);
}
