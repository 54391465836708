import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { Message, MessageSender, Operator } from '../types';

type SetOperatorPayload = {
  // TODO delete once connected to WS
  operator: Operator;
};

export type ChatState = {
  loading: boolean;
  operator: Operator | null;
  messages: Message[];
  incoming: boolean;
};

export const setOperator = createAction(
  '@chat/SET_OPERATOR'
)<SetOperatorPayload>();
export const hide = createAction('@chat/HIDE')();
export const sendMessage = createAction('@chat/SEND_MESSAGE')<{
  sender: MessageSender;
  message: string;
}>();

export type ChatAction =
  | ActionType<typeof setOperator>
  | ActionType<typeof hide>
  | ActionType<typeof sendMessage>;

const _getNormalizedDate = (d: Date) =>
  `${d.getFullYear()}-${d.getMonth()}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}`;

export const chatReducer = createReducer<ChatState, ChatAction>({
  loading: true,
  operator: null,
  messages: [
    {
      normalizedDate: '2020-10-30 10:02',
      date: new Date(2020, 9, 30, 10, 2).getTime(),
      sender: 'operator',
      messages: ['Dobrý den, s čím vám mohu pomoci?'],
    },
  ],
  incoming: true,
})
  .handleAction(setOperator, (state, action) => ({
    ...state,
    loading: false,
    operator: action.payload.operator,
  }))
  .handleAction(hide, (state) => ({
    ...state,
    loading: true,
    operator: null,
  }))
  .handleAction(sendMessage, (state, action) => {
    const now = new Date();
    const nowString = _getNormalizedDate(now);
    const messageWithSameTime = [...state.messages]
      .sort((a, b) => b.date - a.date)
      .find(
        (message) =>
          message.normalizedDate === nowString &&
          message.sender === action.payload.sender
      );

    if (messageWithSameTime) {
      const index = state.messages.indexOf(messageWithSameTime);
      const isLastIndex = state.messages.length - 1 === index;

      if (isLastIndex) {
        messageWithSameTime.messages.push(action.payload.message);
      } else {
        state.messages.push({
          normalizedDate: nowString,
          date: now.getTime(),
          sender: action.payload.sender,
          messages: [action.payload.message],
        });
      }
    } else {
      state.messages.push({
        normalizedDate: nowString,
        date: now.getTime(),
        sender: action.payload.sender,
        messages: [action.payload.message],
      });
    }

    return { ...state };
  });
