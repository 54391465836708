import * as React from 'react';
import styled from 'styled-components';
import { ChevronDownIcon } from '../icons/ChevronDownIcon';

const ButtonElement = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 var(--widget-content-padding);
  border-radius: 0;
  background-color: var(--widget-color-green);
  height: 57px;
  border: none;
  font-size: 18px;
  color: white;
  border-bottom: 1px solid white;
  text-align: left;
  font-weight: bold;

  margin: 0;
`;
const ChevronIcon = styled(ChevronDownIcon)`
  g {
    transform-origin: center center;
    transform: rotate(-90deg);
  }
`;

type Props = {
  onClick: () => void;
};

export const Button: React.FC<Props> = ({ onClick, children }) => (
  <ButtonElement type="button" onClick={onClick}>
    {children}

    <ChevronIcon />
  </ButtonElement>
);
