import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootStateType } from '../../state/store';
import { persistActiveState } from '../../state/view';

const PromoImageElement = styled.img`
  height: 100px;
  border-radius: 4px;
  width: 100%;
  padding: 0;
  display: block;
  cursor: pointer;
`;

export const PromoImage = () => {
  const {
    promoImagePanel: { link, imageUrl },
  } = useSelector((e: RootStateType) => e.global.options!);
  const dispatch = useDispatch();
  const { t } = useTranslation('widget');

  const onClick = () => {
    dispatch(persistActiveState());
    window.location.href = link;
  };

  return (
    <PromoImageElement
      onClick={onClick}
      src={imageUrl}
      alt={t('promo.imageAlt')}
    />
  );
};
