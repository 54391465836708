import { call, put, takeLatest } from 'typed-redux-saga';
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
  getType,
} from 'typesafe-actions';
import { getOperators as getOperatorsQuery } from '../api/operators';
import { Operator } from '../types';

type SearchPayload = {
  searchedTerm: string;
};

export type OperatorsState = {
  loading: boolean;
  error: boolean;
  operators: Operator[] | null;
};

export const getOperatorsAsync = createAsyncAction(
  '@operators/OPERATORS_REQUEST',
  '@operators/OPERATORS_SUCCESS',
  '@operators/OPERATORS_FAILURE'
)<undefined, Operator[], undefined>();
export const search = createAction('@operators/SEARCH')<SearchPayload>();

export type OperatorsAction =
  | ActionType<typeof getOperatorsAsync>
  | ActionType<typeof search>;

export const operatorsReducer = createReducer<OperatorsState, OperatorsAction>({
  loading: false,
  error: false,
  operators: null,
})
  .handleAction(getOperatorsAsync.request, (state) => ({
    ...state,
    loading: true,
    error: false,
  }))
  .handleAction(getOperatorsAsync.success, (state, action) => ({
    ...state,
    loading: false,
    error: false,
    operators: action.payload,
  }))
  .handleAction(getOperatorsAsync.failure, (state) => ({
    ...state,
    loading: false,
    error: true,
  }))
  .handleAction(search, (state, action) => ({
    ...state,
    searchedTerm: action.payload.searchedTerm,
  }));

function* getOperators(
  action: ReturnType<typeof getOperatorsAsync.request>
): Generator {
  try {
    const response = yield* call(getOperatorsQuery);
    if (response.status === 200) {
      yield put(getOperatorsAsync.success(response.json));
    } else {
      yield put(getOperatorsAsync.failure());
    }
  } catch (err) {
    yield put(getOperatorsAsync.failure());
  }
}

export function* getOperatorsSaga() {
  yield takeLatest(getType(getOperatorsAsync.request), getOperators);
}
