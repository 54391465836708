import styled, { css } from 'styled-components';
import { Activable } from '../../../types';

export const WrapperElement = styled.div<Activable>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ active }) =>
    active
      ? css`
          cursor: pointer;
        `
      : css`
          pointer-events: none;
        `}
`;

export const BubbleElement = styled.div<Activable>`
  --dimensions: 50px;
  --color: ${({ active }) =>
    active ? '#ffffff' : 'var(--widget-color-secondary)'};
  --background-color: ${({ active }) =>
    active ? 'var(--widget-color-green)' : 'var(--widget-background-color)'};

  display: flex;
  justify-content: center;
  align-items: center;

  min-height: var(--dimensions);
  max-height: var(--dimensions);
  min-width: var(--dimensions);
  max-width: var(--dimensions);
  border-radius: 50%;
  background-color: var(--background-color);
  color: var(--color);
  margin-bottom: 5px;
`;

export const TextElement = styled.span`
  color: var(--widget-color-secondary);
  font-size: 13px;
`;
