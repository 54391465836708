import * as React from 'react';
import { IMaskMixin } from 'react-imask';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { RootStateType } from '../../state/store';
import { ErrorMessage } from './ErrorMessage';
import { EmailChanges } from './inputchanges';

const WrapperElement = styled.div`
  position: relative;
  display: flex;
`;
const InternalInput: React.FC<{
  inputRef: React.Ref<HTMLInputElement>;
  valid: boolean;
}> = ({ inputRef, valid, ...rest }) => (
  <input ref={inputRef} autoComplete="email" type="email" {...rest} />
);
const InputElement = styled(InternalInput)`
  --border-color: var(--widget-input-border-color);
  --font-size: 20px;

  color: var(--widget--color);
  font-size: var(--font-size);
  background-color: transparent;
  background-clip: padding-box;

  padding: 0 var(--widget-subcontent-padding);
  width: 100%;
  height: 48px;
  margin-bottom: 20px;

  border-radius: 5px;
  border: 1px solid var(--border-color);

  transition: box-shadow 0.3s ease;
  will-change: box-shadow;

  ${({ valid }) =>
    !valid &&
    css`
      --border-color: var(--widget-danger-color);
    `}

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
  }
`;
const MaskedInput = IMaskMixin<{ valid: boolean }, HTMLInputElement>(
  ({ inputRef, ...rest }) => <InputElement inputRef={inputRef} {...rest} />
);

type Props = {
  onChange: (changes: EmailChanges) => void;
  errorMessage: string;
};

export const EmailInput = ({ onChange, errorMessage }: Props) => {
  const {
    callOrder: { emailBlacklist },
  } = useSelector((e: RootStateType) => e.global.options!);
  const [address, setAddress] = React.useState('');
  const [valid, setValid] = React.useState(true);

  const onAccept = (value: string) => setAddress(value);

  React.useEffect(() => {
    const generalyValid =
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(address) &&
      !_testEmailPartially(emailBlacklist, address) &&
      address.length > 0;

    setValid(address.length > 0 ? generalyValid : true);
    onChange({
      valid: generalyValid,
      address,
    });
  }, [onChange, address, emailBlacklist]);

  return (
    <WrapperElement>
      <MaskedInput
        mask="name{@}domain{.}tld[{.}tld]"
        blocks={{
          name: { mask: /^[a-zA-Z0-9._%+-]*$/gi },
          domain: { mask: /^[a-zA-Z0-9-]*$/gi },
          tld: { mask: /^[a-zA-Z]{0,3}$/i },
        }}
        placeholder="@"
        id="email"
        value={address}
        onAccept={onAccept}
        valid={valid}
      />

      {!valid && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </WrapperElement>
  );
};

const _testEmailPartially = (blacklist: string[], address: string) =>
  blacklist.some((blacklistedAddress) => blacklistedAddress.includes(address));
