import { isEmpty } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getOperatorsAsync } from '../../state/operators';
import { RootStateType } from '../../state/store';
import { Operator as OperatorType } from '../../types';
import { Actions } from '../common/Actions';
import { Loader } from './Loader';
import { Operator } from './Operator';
import { SectionInfo } from './SectionInfo';

const OperatorsElement = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--widget-content-padding);
  padding-top: 0;
`;
const OperatorsSectionElement = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--widget-content-background-color);
  margin: calc(-2 * var(--widget-content-padding));
  margin-top: 0;
  border-radius: 10px;
  overflow: auto;
`;
const SearchWrapperElement = styled.div`
  padding: calc(2 * var(--widget-content-padding));
`;
const Search = styled.input<{ color: 'white' | 'black' }>`
  width: 100%;
  height: 48px;
  border: 1px solid var(--widget-input-border-color);
  border-radius: 5px;
  background-color: transparent;
  color: var(--widget--color);
  font-size: 18px;
  padding: 0 calc(2 * var(--widget-content-padding) + 24px) 0
    var(--widget-content-padding);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-search'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: calc(100% - var(--widget-content-padding)) center;

  &::placeholder {
    color: ${({ color }) =>
      color === 'white' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)'};
  }
`;
const OperatorsWrapperElement = styled.div`
  overflow: auto;
`;

export const Operators: React.FC = () => {
  const {
    theme: { color },
  } = useSelector((e: RootStateType) => e.global.options!);
  const { loading, operators } = useSelector((e: RootStateType) => e.operators);
  const dispatch = useDispatch();
  const { t } = useTranslation('operators');
  const [searchedName, setSearchedName] = React.useState<string>();

  React.useEffect(() => {
    if (isEmpty(operators)) {
      dispatch(getOperatorsAsync.request());
    }
  }, [operators, dispatch]);

  return (
    <OperatorsElement>
      <Actions headline={t('headline')} />

      <SectionInfo />

      <OperatorsSectionElement>
        {loading && <Loader />}

        {!loading && (
          <>
            <SearchWrapperElement>
              <Search
                type="text"
                autoComplete="off"
                color={color}
                placeholder={t('search.placeholder')}
                onChange={(e) => setSearchedName(e.target.value)}
              />
            </SearchWrapperElement>

            <OperatorsWrapperElement>
              {operators &&
                [...operators]
                  .sort(_sortByStatusThenByName)
                  .filter(
                    (operator) =>
                      isEmpty(searchedName) ||
                      operator.fullName
                        .toLowerCase()
                        .includes(searchedName!.toLowerCase())
                  )
                  .map((operator) => (
                    <Operator key={operator.operatorId} {...operator} />
                  ))}
            </OperatorsWrapperElement>
          </>
        )}
      </OperatorsSectionElement>
    </OperatorsElement>
  );
};

function _sortByStatusThenByName(left: OperatorType, right: OperatorType) {
  if (left.status === 'online' && right.status === 'offline') {
    return -1;
  }
  if (left.status === 'offline' && right.status === 'online') {
    return 1;
  }

  if (left.fullName < right.fullName) {
    return -1;
  }
  if (left.fullName > right.fullName) {
    return 1;
  }
  return 0;
}
