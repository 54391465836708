import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const InfoSectionElement = styled.div`
  display: flex;
  flex-direction: row;
  margin: var(--widget-content-padding) 0;
`;
const SectionDescriptionElement = styled.span`
  font-size: 15px;
  color: var(--widget-color-secondary);
`;

export const SectionInfo = () => {
  const { t } = useTranslation('phonecall');
  return (
    <InfoSectionElement>
      <SectionDescriptionElement>{t('description')}</SectionDescriptionElement>
    </InfoSectionElement>
  );
};
