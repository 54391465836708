import {
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components';
import { ActionType, createAction, createReducer } from 'typesafe-actions';
import {
  afterShrinkCss,
  beforeGrowCss,
  growCss,
  growWithoutAnimationCss,
  shrinkCss,
} from '../components/widget/styles';
import { ContentType, WidgetMovingState, WidgetPosition } from '../types';

const _stateStyles: {
  [key in WidgetMovingState]: (
    position: WidgetPosition
  ) => FlattenSimpleInterpolation | FlattenInterpolation<ThemeProps<any>>;
} = {
  shrink: () => shrinkCss,
  'after-shrink': () => afterShrinkCss,
  'before-grow': () => beforeGrowCss,
  grow: (position: WidgetPosition) => growCss({ position }),
};

type Direction = 'forward' | 'backward';
type SetActiveContentPayload =
  | {
      direction: Exclude<Direction, 'forward'>;
    }
  | {
      content: ContentType;
      direction: Exclude<Direction, 'backward'>;
      viewData?: any;
    };

type SetWidgetMovingStatePayload = {
  state: WidgetMovingState;
  position: WidgetPosition;
};

export type ViewState = {
  height: number;
  active: boolean;
  moving: boolean;
  canGrow: boolean;
  canShrink: boolean;
  animationStyles:
    | FlattenSimpleInterpolation
    | FlattenInterpolation<ThemeProps<any>>;
  contentActive: boolean;
  showBrandColor: boolean;
  activeContent: ContentType;
  persistState: Omit<ViewState, 'persistState' | 'moving'> | null;
  loadedWithPersistedState: boolean;
  activeContentHistory: ContentType[];
  viewData: any; // each subview has to cast this object to its own type (dirty solution but the easiest and also quickest)
  canClose: boolean;
  forceClose: boolean;
};

export const setHeight = createAction('@view/SET_HEIGHT')<number>();
export const setMoving = createAction('@view/SET_MOVING')<boolean>();
export const setActive = createAction('@view/SET_ACTIVE')<boolean>();
export const setGrowth = createAction('@view/SET_GROWTH')<boolean>();
export const afterGrow = createAction('@view/AFTER_GROW')();
export const afterShrink = createAction('@view/AFTER_SHRINK')();
export const setMovingStateStyles = createAction(
  '@view/SET_MOVING_STATE_STYLES'
)<SetWidgetMovingStatePayload>();
export const toggleContent = createAction('@view/TOGGLE_CONTENT')();
export const toggleBrandColor = createAction('@view/TOGGLE_BRAND_COLOR')();
export const changeContent = createAction(
  '@view/CHANGE_CONTENT'
)<SetActiveContentPayload>();
export const persistActiveState = createAction('@view/PERSIST_ACTIVE_STATE')();
export const loadPersistedState = createAction('@view/LOAD_PERSISTED_STATE')();
export const resetActiveContent = createAction('@view/RESET_CONTENT')();
export const setCanClose = createAction('@view/SET_CAN_CLOSE')<boolean>();
export const setForceClose = createAction('@view/SET_FORCE_CLOSE')<boolean>();

export type ViewAction =
  | ActionType<typeof setHeight>
  | ActionType<typeof setMoving>
  | ActionType<typeof setActive>
  | ActionType<typeof setGrowth>
  | ActionType<typeof afterGrow>
  | ActionType<typeof afterShrink>
  | ActionType<typeof setMovingStateStyles>
  | ActionType<typeof toggleContent>
  | ActionType<typeof toggleBrandColor>
  | ActionType<typeof changeContent>
  | ActionType<typeof persistActiveState>
  | ActionType<typeof loadPersistedState>
  | ActionType<typeof resetActiveContent>
  | ActionType<typeof setCanClose>
  | ActionType<typeof setForceClose>;

export const viewReducer = createReducer<ViewState, ViewAction>({
  height: 0,
  active: false,
  moving: false,
  canGrow: true,
  canShrink: false,
  animationStyles: [],
  contentActive: false,
  showBrandColor: true,
  activeContent: 'main',
  persistState: null,
  loadedWithPersistedState: false,
  activeContentHistory: [],
  viewData: {},
  canClose: true,
  forceClose: false,
})
  .handleAction(setHeight, (state, action) => ({
    ...state,
    height: action.payload,
  }))
  .handleAction(setMoving, (state, action) => ({
    ...state,
    moving: action.payload,
  }))
  .handleAction(setActive, (state, action) => ({
    ...state,
    active: action.payload,
  }))
  .handleAction(setGrowth, (state, action) => ({
    ...state,
    canGrow: action.payload,
    canShrink: !action.payload,
  }))
  .handleAction(afterGrow, (state) => ({
    ...state,
    moving: false,
    canGrow: false,
    canShrink: true,
  }))
  .handleAction(afterShrink, (state) => ({
    ...state,
    moving: false,
    canGrow: true,
    canShrink: false,
  }))
  .handleAction(setMovingStateStyles, (state, action) => {
    const { state: movingState, position } = action.payload;

    return {
      ...state,
      animationStyles: _stateStyles[movingState](position),
    };
  })
  .handleAction(toggleContent, (state) => ({
    ...state,
    contentActive: !state.contentActive,
  }))
  .handleAction(toggleBrandColor, (state) => ({
    ...state,
    showBrandColor: !state.showBrandColor,
  }))
  .handleAction(changeContent, (state, action) => {
    let content: ContentType;
    if (action.payload.direction === 'forward') {
      state.activeContentHistory.push(state.activeContent);
      content = action.payload.content;
      state.viewData = action.payload.viewData;
    } else {
      content = state.activeContentHistory.pop()!;
      state.viewData = {};
    }

    return { ...state, activeContent: content };
  })
  .handleAction(resetActiveContent, (state) => ({
    ...state,
    activeContent: 'main',
    activeContentHistory: [],
  }))
  .handleAction(persistActiveState, (state) => {
    const { ...rest } = { ...state };
    rest.animationStyles = growWithoutAnimationCss();

    return { ...state, persistState: { ...rest } };
  })
  .handleAction(loadPersistedState, (state) => ({
    ...state,
    ...state.persistState,
    loadedWithPersistedState: true,
    persistState: null,
  }))
  .handleAction(setCanClose, (state, action) => ({
    ...state,
    canClose: action.payload,
  }))
  .handleAction(setForceClose, (state, action) => ({
    ...state,
    forceClose: action.payload,
  }));
