import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import {
  hideLandscapeWarning,
  showLandscapeWarning as showLandscapeWarningAction,
} from '../../state/modal';
import { RootStateType } from '../../state/store';
import { Text } from './Text';

type ModalElementProps = {
  visible: boolean;
};

const InternalModal: React.FC<ModalElementProps> = ({
  children,
  visible,
  ...rest
}) => <div {...rest}>{children}</div>;

const ModalElement = styled(InternalModal)`
  position: absolute;
  bottom: 0;
  padding: var(--widget-content-padding);
  width: var(--widget-width);
  display: none;
  color: var(--widget-color);
  background-color: rgba(243, 243, 244, 0.8);
  height: 0;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  z-index: 100;

  ${({ visible }) =>
    visible &&
    css`
      display: flex;
      height: 100%;
    `}
`;
const ContainerElement = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 400px;

  padding: var(--widget-content-padding);

  border-radius: 8px;

  background-color: var(--widget-content-background-color);
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
`;
const CustomText = styled(Text)`
  && {
    margin-bottom: 0;
  }
`;

export const LandscapeWarningModal = () => {
  const { active } = useSelector((e: RootStateType) => e.view);
  const { showLandscapeWarning } = useSelector((e: RootStateType) => e.modal);
  const dispatch = useDispatch();
  const { t } = useTranslation('modals');
  const isLandscape = useMediaQuery({
    query:
      '(min-device-width: 320px) and (max-device-width: 812px) and (orientation: landscape)',
  });

  React.useEffect(() => {
    if (active && isLandscape) {
      // timeout just to let widget expand itself
      setTimeout(() => {
        dispatch(showLandscapeWarningAction());
      }, 300);
    } else {
      dispatch(hideLandscapeWarning());
    }
  }, [dispatch, active, isLandscape]);

  return (
    <ModalElement visible={showLandscapeWarning}>
      <ContainerElement>
        <CustomText>{t('landscape.text')}</CustomText>
      </ContainerElement>
    </ModalElement>
  );
};
