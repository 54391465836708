import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Message as MessageType } from '../../types';

const MessageElement = styled.div`
  margin-top: 5px;

  &:first-of-type {
    margin-top: 0;
  }
`;

const UserMessageElement = styled(MessageElement)`
  align-self: flex-end;
`;
const OperatorMessageElement = styled(MessageElement)`
  align-self: flex-start;
`;

const MessageContentElement = styled.div`
  padding: var(--widget-content-padding);
  word-break: break-word;
`;
const UserMessageContentElement = styled(MessageContentElement)`
  background-color: var(--widget-content-background-color);
  box-shadow: 3px 3px 10px var(--widget-content-box-shadow-color);
  border-radius: 10px;
  border-top-right-radius: 0;
`;
const OperatorMessageContentElement = styled(MessageContentElement)`
  background-color: var(--widget-background-color);
  border-radius: 10px;
  border-top-left-radius: 0;
`;

const TimeElement = styled.div`
  font-size: 11px;
  color: var(--widget-color);
  margin-top: 2px;
`;
const UserTimeELement = styled(TimeElement)`
  text-align: right;
`;

type Props = Pick<MessageType, 'date' | 'messages'>;

const _timeFormat = {
  hour: '2-digit',
  minute: '2-digit',
} as Intl.DateTimeFormatOptions;
const _getMessageText = (message: string, index: number) => (
  <div key={index}>{message}</div>
);

export const UserMessage: React.FC<Props> = ({ date, messages }) => {
  const { i18n } = useTranslation();

  return (
    <UserMessageElement>
      <UserMessageContentElement>
        {messages && messages.map(_getMessageText)}
      </UserMessageContentElement>
      <UserTimeELement>
        {new Date(date).toLocaleTimeString(i18n.language, _timeFormat)}
      </UserTimeELement>
    </UserMessageElement>
  );
};

export const OperatorMessage: React.FC<Props> = ({ date, messages }) => {
  const { i18n } = useTranslation();

  return (
    <OperatorMessageElement>
      <OperatorMessageContentElement>
        {messages && messages.map(_getMessageText)}
      </OperatorMessageContentElement>
      <TimeElement>
        {new Date(date).toLocaleTimeString(i18n.language, _timeFormat)}
      </TimeElement>
    </OperatorMessageElement>
  );
};

export const SystemMessage: React.FC<Props> = ({ date, messages }) => {
  const { i18n } = useTranslation();

  return (
    <MessageElement>
      {messages},{' '}
      {new Date(date).toLocaleTimeString(i18n.language, _timeFormat)}
    </MessageElement>
  );
};
