import * as React from 'react';
import styled, { css } from 'styled-components';
import { Activable } from '../../types';
import { ChevronDownIcon } from '../icons/ChevronDownIcon';

const ToggleElement = styled.div<Activable>`
  display: flex;
  align-items: center;
  color: var(--widget-color-green);
  cursor: pointer;

  g {
    transition: transform 0.3s ease-in-out;
    will-change: transform;
    transform-origin: center center;
    transform: rotateZ(-180deg);
  }

  ${({ active }) =>
    active &&
    css`
      g {
        transform: rotate(0deg);
      }
    `}
`;

type Props = {
  open: boolean;
};

export const Toggle = ({ open }: Props) => (
  <ToggleElement active={open}>
    <ChevronDownIcon />
  </ToggleElement>
);
