import { useTranslation } from 'react-i18next';
import { Button } from '../modals/Button';

export const ErrorModalContent = ({
  elementRef,
  onCloseClick,
}: {
  elementRef: React.RefObject<HTMLButtonElement>;
  onCloseClick: () => void;
}) => {
  const { t } = useTranslation('modals');

  return (
    <div>
      <Button buttonRef={elementRef} onClick={onCloseClick}>
        {t('callcodeError.understood')}
      </Button>
    </div>
  );
};
