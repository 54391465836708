import styled from 'styled-components';

const BasicInfoElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;
const NameELement = styled.span`
  --dimensions: 15px;

  font-size: var(--dimensions);
  line-height: var(--dimensions);
`;
const TagElement = styled.span`
  --dimensions: 13px;

  font-size: var(--dimensions);
  color: var(--widget-color-secondary);
`;

type Props = {
  fullName: string;
  tag: string;
};

export const BasicInfo = ({ fullName, tag }: Props) => (
  <BasicInfoElement>
    <NameELement>{fullName}</NameELement>
    <TagElement>{tag}</TagElement>
  </BasicInfoElement>
);
