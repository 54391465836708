import * as React from 'react';
import styled, { keyframes } from 'styled-components';

const bouncing = keyframes`
	0% {
		opacity: 0;
	}
	33% {
		bottom: 0.5;
	}
	66% {
		opacity: 0.8;
	}
`;

const IncommingMessageIndicatorElement = styled.div`
  border-radius: 10px;
  background-color: var(--widget-background-color);
  padding: 10px;
  display: flex;
  align-self: flex-start;
  margin-top: 5px;
`;

const DotElement = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: var(--widget-color);
  opacity: 0;

  &:last-of-type {
    margin-right: 0;
  }

  &:nth-of-type(1) {
    animation: ${bouncing} 1s infinite ${(-1 + 1) * 0.16}s linear;
  }

  &:nth-of-type(2) {
    animation: ${bouncing} 1s infinite ${(-1 + 2) * 0.16}s linear;
  }

  &:nth-of-type(3) {
    animation: ${bouncing} 1s infinite ${(-1 + 3) * 0.16}s linear;
  }
`;

export const IncommingMessageIndicator: React.FC = () => (
  <IncommingMessageIndicatorElement>
    <DotElement />
    <DotElement />
    <DotElement />
  </IncommingMessageIndicatorElement>
);
