import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootStateType } from '../../state/store';
import { Message, MessageSender } from '../../types';
import { IncommingMessageIndicator } from './IncommingMessageIndicator';
import { Loader } from './Loader';
import { OperatorMessage, SystemMessage, UserMessage } from './Message';

type MessagesDisplayElementProps = {
  loading: boolean;
  innerRef: React.RefObject<HTMLDivElement>;
};

const InternalMessagesDisplayElement: React.FC<MessagesDisplayElementProps> = ({
  loading,
  children,
  innerRef,
  ...rest
}) => (
  <div ref={innerRef} {...rest}>
    {children}
  </div>
);

const MessagesDisplayElement = styled(InternalMessagesDisplayElement)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: var(--widget-content-background-color);
  border-radius: 4px;
  overflow-y: auto;
  padding: calc(2 * var(--widget-content-padding));
  margin: 0 calc(-2 * var(--widget-content-padding));
  margin-top: 0;
`;

const _messageTypesToComponents: {
  [key in MessageSender]: (message: Message, index: number) => JSX.Element;
} = {
  user: ({ date, messages }, index) => (
    <UserMessage date={date} messages={messages} key={index} />
  ),
  operator: ({ date, messages }, index) => (
    <OperatorMessage date={date} messages={messages} key={index} />
  ),
  system: ({ date, messages }, index) => (
    <SystemMessage date={date} messages={messages} key={index} />
  ),
};

export const MessagesDisplay: React.FC = () => {
  const { loading, messages, incoming } = useSelector(
    (e: RootStateType) => e.chat
  );

  const messagesRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (messagesRef?.current) {
      const element = messagesRef.current;

      element.scrollTop = element.scrollHeight;
    }
  }, [messagesRef]);

  return (
    <MessagesDisplayElement innerRef={messagesRef} loading={loading}>
      {!loading &&
        messages &&
        messages.map((message, index) =>
          _messageTypesToComponents[message.sender](message, index)
        )}
      {!loading && incoming && <IncommingMessageIndicator />}
      {loading && <Loader />}
    </MessagesDisplayElement>
  );
};
