import * as React from 'react';
import { IMaskMixin } from 'react-imask';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { RootStateType } from '../../state/store';
import { PhoneFormat, PhoneNumber } from '../../types';
import { ChevronDownIcon } from '../icons/ChevronDownIcon';
import { ErrorMessage } from './ErrorMessage';
import { NumberChanges } from './numberchanges';
import { PrefixesDropdown } from './PrefixesDropdown';

const WrapperElement = styled.div<{ valid: boolean }>`
  --border-color: var(--widget-input-border-color);
  --font-size: 20px;

  display: flex;
  flex-direction: row;

  height: 48px;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease;
  will-change: box-shadow;
  position: relative;

  ${({ valid }) =>
    !valid &&
    css`
      --border-color: var(--widget-danger-color);
    `}

  &:focus-within {
    box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
  }
`;
const FlagElementWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 65px;
  max-width: 65px;
  background-color: var(--widget-background-color);
  border-radius: 5px 0 0 5px;
  cursor: pointer;
`;
const ImageElement = styled.img`
  width: 24px;
  margin-right: 5px;
`;
const PrefixElement = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 65px;
  max-width: 65px;
  font-size: var(--font-size);
  padding-right: 5px;
`;
const InternalInput: React.FC<{
  inputRef: React.Ref<HTMLInputElement>;
}> = ({ inputRef, ...rest }) => (
  <input ref={inputRef} autoComplete="off" type="tel" {...rest} />
);
const InputElement = styled(InternalInput)`
  --border-color: var(--widget-input-border-color);

  color: var(--widget--color);
  border-width: 0;
  font-size: var(--font-size);
  padding: 0;
  width: 100%;
  background-color: transparent;
  border-radius: 0 5px 5px 0;

  &:focus {
    outline: none;
  }
`;
const MaskedInput = IMaskMixin<{}, HTMLInputElement>(
  ({ inputRef, ...rest }) => <InputElement inputRef={inputRef} {...rest} />
);

type Props = {
  onChange: (changes: NumberChanges) => void;
  errorMessage: string;
};

export const PhoneNumberInput: React.FC<Props> = ({
  onChange,
  errorMessage,
}) => {
  const {
    phoneNumberOptions: { phoneFormats, blacklist },
  } = useSelector((e: RootStateType) => e.global.options!.i18n);
  const { phoneFormat } = useSelector((e: RootStateType) => e.settings);

  const [selectedFormat, setSelectedFormat] = React.useState(phoneFormat);
  const [phoneNumber, setPhoneNumber] = React.useState<PhoneNumber>({
    prefix: selectedFormat.prefix,
    number: '',
  });
  const [showFormatDropdown, setShowFormatDropdown] = React.useState(false);
  const [valid, setValid] = React.useState(true);

  const onClick = () => {
    setShowFormatDropdown(!showFormatDropdown);
  };
  const onFormatChange = (format: PhoneFormat) => {
    setSelectedFormat(format);
    setPhoneNumber({
      prefix: format.prefix,
      number: phoneNumber.number,
    });
    setShowFormatDropdown(false);
  };
  const onAccept = (value: string) => {
    const escapedValue = value.replace(/[^\d]/gi, '');
    setPhoneNumber({
      prefix: phoneNumber.prefix,
      number: escapedValue,
    });
  };

  React.useEffect(() => {
    const generalyValid =
      phoneNumber.number.length === selectedFormat.validLength &&
      !blacklist.includes(phoneNumber.number);
    setValid(phoneNumber.number.length > 0 ? generalyValid : true);
    onChange({
      valid: generalyValid,
      phoneNumber,
    });
  }, [onChange, phoneNumber, selectedFormat, blacklist]);

  return (
    <WrapperElement valid={valid}>
      <FlagElementWrapper onClick={onClick}>
        <ImageElement src={selectedFormat.imageUrl} alt="" />
        <ChevronDownIcon width={14} height={14} />
      </FlagElementWrapper>

      <PrefixesDropdown
        active={showFormatDropdown}
        formats={phoneFormats}
        onFormatChange={onFormatChange}
      />

      <PrefixElement>{selectedFormat.prefix}</PrefixElement>

      <MaskedInput
        id="phone"
        value={phoneNumber.number}
        mask={selectedFormat.format}
        onAccept={onAccept}
        placeholder={selectedFormat.format}
      />

      {!valid && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </WrapperElement>
  );
};
