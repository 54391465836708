import * as React from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { createGlobalStyle } from 'styled-components';
import { Widget } from './components/widget/Widget';
import { useI18n } from './hooks/useI18n';
import { getOptionsAsync } from './state/global';
import { setupNotifications } from './state/notification';
import { RootStateType, useApplicationStore } from './state/store';

const GlobalStyle = createGlobalStyle`
	html,
	body {
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
		margin: 0;
		padding: 0;
		font-size: 14px;
		background-color: rgb(248, 249, 251);
	}
`;

export const App = () => {
  const appStore = useApplicationStore();

  return (
    <>
      <GlobalStyle />
      <Provider store={appStore.store}>
        <PersistGate loading={null} persistor={appStore.persistor}>
          <AppChild />
        </PersistGate>
      </Provider>
    </>
  );
};

const AppChild = () => {
  const {
    global: { loading, options },
    view: { persistState },
  } = useSelector((e: RootStateType) => e);
  const dispatch = useDispatch();
  const { i18nLoaded, setI18n } = useI18n();

  React.useEffect(() => {
    if (loading && !options) {
      dispatch(getOptionsAsync.request());
    }
  }, [loading, options, dispatch]);

  React.useEffect(() => {
    if (!loading && options && !i18nLoaded) {
      // eslint-disable-next-line no-debugger
      debugger;
      setI18n({
        resources: options.l10n.translations,
        fallbackLanguage: options.l10n.locale,
        supportedLanguages: options.l10n.locales.map((locale) => locale.code),
      });
    }
  }, [loading, options, i18nLoaded, setI18n]);

  React.useEffect(() => {
    if (!loading && options && !i18nLoaded) {
      dispatch(
        setupNotifications({
          ...options.notifications,
          canShow: !persistState,
        })
      );
    }
  }, [loading, options, i18nLoaded, dispatch, persistState]);

  return options && i18nLoaded ? <Widget options={options} /> : null;
};
