import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootStateType } from '../../state/store';
import { persistActiveState } from '../../state/view';
import { Hyperlink } from './Hyperlink';

const HyperlinksElement = styled.div`
  margin-bottom: calc(-1 * var(--widget-subcontent-padding));
  padding: calc(var(--widget-content-padding) + 5px);
`;

const Headline = styled.h3`
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 10px;
`;

const ContainerElement = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Hyperlinks = () => {
  const {
    hyperlinksPanel: { items: hyperlinks },
  } = useSelector((e: RootStateType) => e.global.options!);
  const dispatch = useDispatch();
  const { t } = useTranslation('widget');

  const onClick = (link: string) => {
    dispatch(persistActiveState());
    window.location.href = link;
  };

  return (
    <HyperlinksElement>
      <Headline>{t('quickLinks.headline')}</Headline>

      <ContainerElement>
        {hyperlinks &&
          hyperlinks.map((link, index) => (
            <Hyperlink key={link.text} onClick={() => onClick(link.link)}>
              {link.text}
            </Hyperlink>
          ))}
      </ContainerElement>
    </HyperlinksElement>
  );
};
