import { call, put, takeLatest } from 'typed-redux-saga';
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
  getType,
} from 'typesafe-actions';
import {
  InvalidPasswordResponse,
  validate as validateQuery,
  ValidPasswordResponse,
} from '../api/callcode';

export type CallCodeState = {
  code: string;
  codeEntered: boolean;
  valid: boolean | null;
  validating: boolean;
  error: string | null;
  validationResponse: ValidPasswordResponse | null;
};

export const setCode = createAction('@callcode/SET_CODE')<string>();
export const validateAsync = createAsyncAction(
  '@callcode/VALIDATE_REQUEST',
  '@callcode/VALIDATE_SUCCESS',
  '@callcode/VALIDATE_FAILURE'
)<string, ValidPasswordResponse, InvalidPasswordResponse>();

export type CallCodeAction =
  | ActionType<typeof setCode>
  | ActionType<typeof validateAsync>;

export const callCodeReducer = createReducer<CallCodeState, CallCodeAction>({
  code: '',
  codeEntered: false,
  valid: null,
  validating: false,
  error: null,
  validationResponse: null,
})
  .handleAction(setCode, (state, action) => ({
    ...state,
    code: action.payload,
    codeEntered: action.payload.length === 4,
    valid: null,
    validating: false,
    error: null,
  }))
  .handleAction(validateAsync.request, (state) => ({
    ...state,
    validating: true,
    error: null,
  }))
  .handleAction(validateAsync.success, (state, action) => ({
    ...state,
    code: '',
    codeEntered: false,
    valid: action.payload.IsValid,
    validating: false,
    error: null,
    validationResponse: action.payload,
  }))
  .handleAction(validateAsync.failure, (state, action) => ({
    ...state,
    codeEntered: false,
    valid: false,
    validating: false,
    error: action.payload.ErrorMessage,
  }));

function* validate(
  action: ReturnType<typeof validateAsync.request>
): Generator {
  try {
    const response = yield* call(validateQuery, { password: action.payload });
    if (response.status === 200) {
      yield put(validateAsync.success(response.data as ValidPasswordResponse));
    } else {
      yield put(
        validateAsync.failure(response.data as InvalidPasswordResponse)
      );
    }
  } catch {
    yield put(
      validateAsync.failure({
        IsValid: false,
        ErrorMessage: 'networkError',
      } as InvalidPasswordResponse)
    );
  }
}

export function* validateSaga() {
  yield takeLatest(getType(validateAsync.request), validate);
}
