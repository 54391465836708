import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootStateType } from '../../state/store';
import { Input } from './Input';

const InteractionsElement = styled.div`
  display: flex;

  margin: calc(-2 * var(--widget-content-padding));
  margin-top: 0;

  min-height: 45px;
  max-height: 45px;

  border-radius: 0 0 8px 8px;
  border-top: 1px solid var(--widget-input-border-color);

  background-color: var(--widget-content-background-color);
`;
const InputWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const Interactions: React.FC = () => {
  const { loading } = useSelector((e: RootStateType) => e.chat);

  return (
    <InteractionsElement>
      <InputWrapper>{!loading && <Input />}</InputWrapper>
    </InteractionsElement>
  );
};
