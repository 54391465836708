import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const InfoSectionElement = styled.div`
  display: flex;
  flex-direction: row;
  margin: var(--widget-content-padding) 0;

  > div {
    flex-basis: 50%;
  }
`;
const SectionDescriptionElement = styled.span`
  font-size: 15px;
  color: var(--widget-color-secondary);
`;
const ImagesWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const ImageElement = styled.img`
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 50%;

  &:first-of-type {
    margin: 0 -10px;
    z-index: 5;
  }

  &:last-of-type {
    z-index: 4;
  }
`;

export const SectionInfo = () => {
  const { t } = useTranslation('operators');
  return (
    <InfoSectionElement>
      <SectionDescriptionElement>{t('description')}</SectionDescriptionElement>

      <ImagesWrapper>
        <ImageElement
          src="/img/main/operator_male.png"
          srcSet="/img/main/operator_male.png, /img/main/operator_male@2x.png 2x"
          alt={t('operators.imageAlt')}
        />

        <ImageElement
          src="/img/main/operator_female.png"
          srcSet="/img/main/operator_female.png, /img/main/operator_female@2x.png 2x"
          alt={t('operators.imageAlt')}
        />
      </ImagesWrapper>
    </InfoSectionElement>
  );
};
