import * as React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { RootStateType } from '../../state/store';
import { ErrorMessage } from './ErrorMessage';
import { MessageChanges } from './inputchanges';

const WrapperElement = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
`;
const TextareaElement = styled.textarea<{ valid: boolean }>`
  --border-color: var(--widget-input-border-color);
  --font-size: 20px;

  flex-grow: 1;

  resize: none;

  color: var(--widget--color);
  font-size: var(--font-size);
  background-color: transparent;
  background-clip: padding-box;

  padding: var(--widget-subcontent-padding);
  margin-bottom: 20px;

  border-radius: 5px;
  border: 1px solid var(--border-color);

  transition: box-shadow 0.3s ease;
  will-change: box-shadow;

  ${({ valid }) =>
    !valid &&
    css`
      --border-color: var(--widget-danger-color);
    `}

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
  }
`;

type Props = {
  onChange: (changes: MessageChanges) => void;
  errorMessage: string;
};

export const MessageInput = ({ onChange, errorMessage }: Props) => {
  const {
    callOrder: { messageBlacklist },
  } = useSelector((e: RootStateType) => e.global.options!);
  const [value, setValue] = React.useState('');
  const [valid, setValid] = React.useState(true);

  const onInternalChange = (newValue: string) => {
    const sanitizedValue = newValue.trim();
    setValue(sanitizedValue);

    const generalyValid =
      !messageBlacklist.some((blacklistedTest) =>
        sanitizedValue.includes(blacklistedTest)
      ) && sanitizedValue.length > 0;
    setValid(sanitizedValue.length > 0 ? generalyValid : true);
    onChange({
      valid: generalyValid,
      message: sanitizedValue,
    });
  };

  return (
    <WrapperElement>
      <TextareaElement
        id="message"
        value={value}
        onChange={(e) => onInternalChange(e.target.value)}
        valid={valid}
      />

      {!valid && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </WrapperElement>
  );
};
