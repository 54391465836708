import { isEmpty } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import {
  hideNotification,
  setReshowPromoImage,
  showNotification,
} from '../../state/notification';
import { RootStateType } from '../../state/store';
import { WidgetPosition } from '../../types';
import { Close } from './Close';

type NotificationElementProps = {
  innerRef: React.RefObject<HTMLDivElement>;
  active: boolean;
  position: WidgetPosition;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const InternalNotificationWrapperElement: React.FC<NotificationElementProps> = ({
  innerRef,
  active,
  position,
  children,
  ...rest
}) => (
  <div ref={innerRef} {...rest}>
    {children}
  </div>
);

const NotificationWrapperElement = styled(InternalNotificationWrapperElement)`
  --notification-width: 305px;

  position: absolute;
  width: var(--notification-width);
  opacity: 0;
  transition: all 1s ease-in-out;
  z-index: 5;

  ${({ position }) =>
    position === 'bottom-right' &&
    css`
      right: calc(-100% - 300px);
      left: unset;
    `};
  ${({ position }) =>
    position === 'bottom-left' &&
    css`
      left: calc(-100% - 300px);
      right: unset;
    `};
  bottom: calc(100% + 10px);

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
    `};

  ${({ active, position }) =>
    active &&
    position === 'bottom-right' &&
    css`
      right: 0;
    `};

  ${({ active, position }) =>
    active &&
    position === 'bottom-left' &&
    css`
      left: 0;
    `};
`;

const NotificationElement = styled.div`
  background-color: var(--widget-background-color);
  color: var(--widget-color);
  border-radius: 8px;
  padding: var(--widget-subcontent-padding);
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
`;

const PromoImageElement = styled.div`
  height: 75px;
  margin-top: 10px;

  border-radius: 8px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);

  background-image: var(--background-image);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  cursor: pointer;
`;

const Headline = styled.h2`
  font-size: 24px;
  margin: 0;
  margin-bottom: 10px;
`;

const Description = styled.span`
  font-size: 15px;
`;

type Props = {
  position: WidgetPosition;
};

export const Notification: React.FC<Props> = ({ position }) => {
  const { active, canShow, delay, duration } = useSelector(
    (e: RootStateType) => e.notification
  );
  const { showImage, imageUrl, link } = usePromoImage();
  const dispatch = useDispatch();
  const { t } = useTranslation('notifications');

  const [autoTimeout, setAutoTimeout] = React.useState<number | undefined>(
    undefined
  );
  const notificationRef = React.useRef<HTMLDivElement>(null);

  const onMouseEneter = React.useCallback(() => {
    window.clearTimeout(autoTimeout);
    setAutoTimeout(autoTimeout);
  }, [autoTimeout]);
  const onMouseLeave = React.useCallback(() => {
    setAutoTimeout(
      window.setTimeout(() => dispatch(hideNotification()), duration)
    );
  }, [dispatch, duration]);
  const redirect = () => {
    window.location.href = link;
  };

  React.useLayoutEffect(() => {
    if (canShow) {
      setTimeout(() => {
        // check again, widget might be open now
        if (canShow) {
          dispatch(showNotification());
        }
      }, delay);

      setAutoTimeout(
        window.setTimeout(() => {
          if (canShow) {
            dispatch(showNotification());
          }
        }, delay + duration)
      );
    }
  }, [canShow, dispatch, duration, delay]);

  React.useEffect(() => {
    if (!notificationRef?.current) {
      return undefined;
    }

    const element = notificationRef.current;
    element.addEventListener('mouseenter', onMouseEneter);
    element.addEventListener('mouseleave', onMouseLeave);

    return () => {
      element.removeEventListener('mouseenter', onMouseEneter);
      element.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [notificationRef, onMouseEneter, onMouseLeave]);

  return (
    <NotificationWrapperElement
      innerRef={notificationRef}
      active={active}
      position={position}
    >
      <NotificationElement>
        <Close />

        <div>
          <Headline>{t('headline')}</Headline>
          <Description>{t('description')}</Description>
        </div>
      </NotificationElement>

      {showImage && (
        <PromoImageElement
          onClick={redirect}
          style={
            {
              '--background-image': `url('${imageUrl}')`,
            } as React.CSSProperties
          }
        />
      )}
    </NotificationWrapperElement>
  );
};

const usePromoImage = () => {
  const [showImage, setShowImage] = React.useState(false);
  const { reshowPromoImageAfter, imageUrl, link } = useSelector(
    (e: RootStateType) => e.notification
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isEmpty(imageUrl)) {
      return;
    }

    if (
      !reshowPromoImageAfter ||
      (reshowPromoImageAfter && new Date() >= reshowPromoImageAfter)
    ) {
      setShowImage(true);

      const tomorrow = new Date();
      tomorrow.setTime(tomorrow.getTime() + 24 * 3600 * 1000);
      dispatch(setReshowPromoImage({ after: tomorrow }));
    }
  }, [imageUrl, reshowPromoImageAfter, dispatch]);

  return { showImage, imageUrl, link };
};
