import i18n, { Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as React from 'react';
import { initReactI18next } from 'react-i18next';

export const useI18n = () => {
  const [i18nLoaded, setI18nLoaded] = React.useState(false);

  const setI18n = React.useCallback(
    ({
      resources,
      fallbackLanguage,
      supportedLanguages,
    }: {
      resources: Resource;
      fallbackLanguage: string;
      supportedLanguages: string[];
    }) => {
      if (!i18nLoaded) {
        i18n
          .use(LanguageDetector)
          .use(initReactI18next)
          .init({
            detection: {
              lookupLocalStorage: 'livesale_i18nextLng',
              lookupSessionStorage: 'livesale_i18nextLng',
              caches: ['localStorage'],
            },
            resources,
            fallbackLng: fallbackLanguage,
            supportedLngs: supportedLanguages,
            ns: ['widget', 'notifications', 'chat'],
            defaultNS: 'widget',
            nonExplicitSupportedLngs: false,
            interpolation: {
              escapeValue: false, // handled by React itself
            },
          })
          .then((loaded) => setI18nLoaded(true))
          .catch(() => setI18nLoaded(false));
      }
    },
    [i18nLoaded]
  );

  return { i18nLoaded, setI18n };
};
