import * as React from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { Activable } from '../../../types';
import { ChevronDownIcon } from '../../icons/ChevronDownIcon';
import {
  DropdownActiveStyles,
  DropdownContentStyles,
  DropdownItemStyles,
  DropdownStyles,
  DropdownWrapperStyles,
} from '../styles/Dropdown.styles';

const DropdownWrapperElement = styled.div`
  ${DropdownWrapperStyles};
`;
const DropdownContentElement = styled.div`
  ${DropdownContentStyles};
`;
const PlaceholderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;
const ChevronIcon = styled(ChevronDownIcon)`
  margin-left: 5px;
`;
const DropdownElement = styled.div<Activable & { top: number; left: number }>`
  ${DropdownStyles};

  ${({ active }) => active && DropdownActiveStyles};
`;
const DropdownItemElement = styled.div`
  ${DropdownItemStyles};
`;

type DropdownChildrenFn = (
  onClose: () => void
) => React.ReactElement<HTMLElement>[];
type Props = {
  placeholder: React.ReactElement<HTMLElement>;
  className?: string;
} & { children: DropdownChildrenFn };

export const Dropdown = ({ placeholder, className, children }: Props) => {
  const [showDropdown, setShowDropdown] = React.useState(false);
  const [activateDropdown, setActivateDropdown] = React.useState(false);
  const dropdownWrapperRef = React.useRef<HTMLDivElement>(null);
  const [dropdownPosition, setDropdownPosition] = React.useState({
    top: 0,
    left: 0,
  });

  const onClose = React.useCallback(() => setShowDropdown(false), []);
  useOnClickOutside(dropdownWrapperRef, onClose);

  React.useEffect(() => {
    if (showDropdown) {
      const {
        top,
        height,
        left,
      } = dropdownWrapperRef.current!.getBoundingClientRect();

      setDropdownPosition({
        top: Math.round(top) + height,
        left: Math.round(left),
      });
      setActivateDropdown(true);
    } else {
      setActivateDropdown(false);
    }
  }, [showDropdown, dropdownWrapperRef]);

  return (
    <DropdownWrapperElement ref={dropdownWrapperRef} className={className}>
      <DropdownContentElement
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
      >
        <PlaceholderWrapper>{placeholder}</PlaceholderWrapper>

        <ChevronIcon width={12} height={12} />
      </DropdownContentElement>

      <DropdownElement
        active={activateDropdown}
        top={dropdownPosition.top}
        left={dropdownPosition.left}
      >
        {children(onClose)}
      </DropdownElement>
    </DropdownWrapperElement>
  );
};

export const DropdownItem: React.FC<{ onClick?: () => void }> = ({
  children,
  onClick,
}) => <DropdownItemElement onClick={onClick}>{children}</DropdownItemElement>;
