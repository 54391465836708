import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { RootStateType } from '../../state/store';
import { changeContent } from '../../state/view';
import { ContentType } from '../../types';
import { Actions } from '../common/Actions';
import { Hyperlinks } from '../hyperlinks/Hyperlinks';
import { PromoImage } from '../promoimages/PromoImage';
import { Button } from './Button';
import { Operators } from './Operators';

export const HelpElement = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--widget-content-padding);
  padding-top: 0;
`;
const Headline = styled.h2`
  margin: 0;
  margin-bottom: var(--widget-subcontent-padding);
  font-size: 24px;
`;
const HelpSectionElement = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--widget-content-background-color);
  margin: calc(-2 * var(--widget-content-padding));
  padding: var(--widget-content-padding);
  margin-top: 0;
  border-radius: 10px;
  overflow: auto;
`;
const ButtonsWrapperElement = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 5px;
  overflow: hidden;

  margin-bottom: 10px;
`;
const HelpItemElement = styled.div<{ useBoxShadow: boolean }>`
  width: 100%;
  background-color: var(--widget-content-background-color);
  border-radius: 4px;

  ${({ useBoxShadow }) =>
    useBoxShadow
      ? css`
          box-shadow: 0px 2px 0px var(--widget-color-green),
            0 1px 6px rgba(0, 0, 0, 0.1);
          margin-bottom: 12px;

          &:last-of-type {
            margin-bottom: 2px;
          }
        `
      : css`
          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }
        `}
`;

const _helpTypes: ContentType[] = [
  'chat',
  'phonecall',
  'videocall',
  'callorder',
  'message',
];

export const Help = () => {
  const { activeSections } = useSelector(
    (e: RootStateType) => e.global.options!
  );
  const dispatch = useDispatch();
  const elementRef = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation('help');
  const isMobile = useMediaQuery({
    query: 'only screen and (max-width: 599px)',
  });
  const [links, setLinks] = React.useState(_helpTypes);

  const onClick = (type: ContentType) =>
    dispatch(changeContent({ direction: 'forward', content: type }));

  React.useEffect(() => {
    if (isMobile) {
      setLinks(_helpTypes.filter((type) => type !== 'videocall'));
    } else {
      setLinks(_helpTypes);
    }
  }, [isMobile]);

  return (
    <HelpElement ref={elementRef}>
      <Actions />

      <Headline>{t('headline')}</Headline>

      <Operators />

      <HelpSectionElement>
        <ButtonsWrapperElement>
          {links.map((type) => (
            <Button key={type} onClick={() => onClick(type)}>
              {t(`types.${type}`)}
            </Button>
          ))}
        </ButtonsWrapperElement>

        {activeSections.includes('promoimage') && (
          <HelpItemElement useBoxShadow={false}>
            <PromoImage />
          </HelpItemElement>
        )}

        {activeSections.includes('hyperlinks') && (
          <HelpItemElement useBoxShadow={true}>
            <Hyperlinks />
          </HelpItemElement>
        )}
      </HelpSectionElement>
    </HelpElement>
  );
};
