import { Resource } from 'i18next';

export type WidgetPosition = 'bottom-left' | 'bottom-right';

export type WidgetOptions = {
  position: WidgetPosition;
  theme: Theme;
  notifications: Notifications;
  activeSections: Section[];
  l10n: {
    locale: LanguageCode;
    locales: Language[];
    translations: Resource;
  };
  i18n: {
    phoneNumberOptions: PhoneNumberOptions;
  };
  guidesPanel: GuidesPanel;
  callCodePanel: CallCodePanel;
  promoImagePanel: PromoImagePanel;
  hyperlinksPanel: HyperlinksPanel;
  callOrder: CallOrders;
};

export type LanguageCode = string;

export type Language = {
  code: LanguageCode;
  imageUrl: string;
};

export type WidgetMovingState =
  | 'shrink'
  | 'after-shrink'
  | 'before-grow'
  | 'grow';

export type Activable = {
  active: boolean;
};

export type Theme = {
  color: 'white' | 'black';
  brandColor: string;
  primaryColor: string;
};

export type Notifications = {
  delay: number;
  duration: number;
  imageUrl: string | null;
  link: string;
  activeSounds: boolean;
};

export enum ShowOrHideNotification {
  halfday = 'halfday',
  day = 'day',
  indefinitely = 'indefinitely',
  show = 'show',
}

export type Section = 'guides' | 'callcode' | 'promoimage' | 'hyperlinks';

export type Hyperlink = {
  text: string;
  link: string;
};

export type ContentType =
  | 'main'
  | 'chat'
  | 'operators'
  | 'help'
  | 'message'
  | 'callorder'
  | 'phonecall'
  | 'videocall';

export type ToggleablePanel = { isEnabled: boolean };

export type GuidesPanel = ToggleablePanel & {
  guides: Guides[];
};

export type CallCodePanel = ToggleablePanel;

export type PromoImagePanel = ToggleablePanel & PromoSection;

export type HyperlinksPanel = ToggleablePanel & { items: Hyperlink[] };

export type Guides = {
  component: Extract<ContentType, 'message' | 'callorder'>;
};

export type PromoSection = {
  link: string;
  imageUrl: string;
};

export type PhoneNumberOptions = {
  defaultCode: string;
  phoneFormats: PhoneFormat[];
  blacklist: string[];
};

export type CallOrders = {
  emailBlacklist: string[];
  messageBlacklist: string[];
};

export type PhoneFormat = {
  code: string;
  imageUrl: string;
  prefix: string;
  format: string;
  validLength: number;
};

export type PhoneNumber = {
  prefix: string;
  number: string;
};

export type MessageSender = 'user' | 'operator' | 'system';

export type Message = {
  sender: MessageSender;
  date: number;
  normalizedDate: string;
  messages: string[];
};

export type Operator = {
  operatorId: string;
  fullName: string;
  tag: string;
  photoUrl: string;
  status: Status;
};

export type Status = 'online' | 'offline';
