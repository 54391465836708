import * as React from 'react';
import styled from 'styled-components';
import { ChatPath } from '../icons/ChatPath';
import { ClosePath } from '../icons/ClosePath';

const OpenCloseIconElement = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--widget-color);
`;

type Props = {
  active: boolean;
};

export const OpenCloseIcon: React.FC<Props> = ({ active }) => (
  <OpenCloseIconElement
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24"
    height="24"
    focusable="false"
    aria-labelledby="openclose-title"
  >
    <title id="openclose-title">
      {active ? 'Zavřít widget' : 'Otevřít widget'}
    </title>
    {!active && <ChatPath />}
    {active && <ClosePath />}
  </OpenCloseIconElement>
);
