import styled from 'styled-components';
import { CloseButton } from './CloseButton';

const CloseElement = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;
`;

export const Close = () => (
  <CloseElement>
    <CloseButton />
  </CloseElement>
);
