import * as React from 'react';
import styled from 'styled-components';
import { Activable } from '../../../types';

const CollapsibleElement = styled.div`
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  will-change: height;
`;
const ContentElement = styled.div`
  margin: 10px;
  margin-top: 0;
`;

type Props = {
  className?: string;
} & Activable;

export const CollapsibleContent: React.FC<Props> = ({
  active,
  className,
  children,
}) => {
  const collapsibleRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (active) {
      const height = collapsibleRef.current!.scrollHeight;
      collapsibleRef.current!.style.height = `${height}px`;

      const transitionEnd = (e: TransitionEvent) => {
        collapsibleRef.current!.removeEventListener(
          'transitionend',
          transitionEnd
        );
        collapsibleRef.current!.style.height = 'auto';
      };

      collapsibleRef.current!.addEventListener('transitionend', transitionEnd);
    } else {
      const height = collapsibleRef.current!.scrollHeight;
      const elementTransition = collapsibleRef.current!.style.transition;

      collapsibleRef.current!.style.transition = '';

      requestAnimationFrame(() => {
        collapsibleRef.current!.style.height = `${height}px`;
        collapsibleRef.current!.style.transition = elementTransition;

        requestAnimationFrame(() => {
          collapsibleRef.current!.style.height = '0px';
        });
      });
    }
  }, [collapsibleRef, active]);

  return (
    <CollapsibleElement ref={collapsibleRef} className={className}>
      <ContentElement>{children}</ContentElement>
    </CollapsibleElement>
  );
};
