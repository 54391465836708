import { call, put, takeLatest } from 'typed-redux-saga';
import {
  ActionType,
  createAsyncAction,
  createReducer,
  getType,
} from 'typesafe-actions';
import { getOptions as getOptionsQuery } from '../api/global';
import { WidgetOptions } from '../types';

export type GlobalState = {
  options: WidgetOptions | null;
  loading: boolean;
  error: string | null;
};

export const getOptionsAsync = createAsyncAction(
  '@global/OPTIONS_REQUEST',
  '@global/OPTIONS_SUCCESS',
  '@global/OPTIONS_FAILURE'
)<undefined, WidgetOptions, Error>();

export type GlobalAction = ActionType<typeof getOptionsAsync>;

export const globalReducer = createReducer<GlobalState, GlobalAction>({
  options: null,
  loading: true,
  error: null,
})
  .handleAction(getOptionsAsync.request, (state) => ({
    ...state,
    loading: true,
    error: null,
  }))
  .handleAction(getOptionsAsync.success, (state, action) => ({
    ...state,
    options: action.payload,
    loading: false,
    error: null,
  }))
  .handleAction(getOptionsAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload.message,
  }));

function* getOptions(
  action: ReturnType<typeof getOptionsAsync.request>
): Generator {
  try {
    const response = yield* call(getOptionsQuery);

    if (response.status === 200) {
      // eslint-disable-next-line no-debugger
      debugger;
      yield put(getOptionsAsync.success(response.data));
    } else {
      yield put(getOptionsAsync.failure(new Error('Failed to load options')));
    }
  } catch (err) {
    yield put(getOptionsAsync.failure(err));
  }
}

export function* getOptionsSaga() {
  yield takeLatest(getType(getOptionsAsync.request), getOptions);
}
