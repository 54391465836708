import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { toggleMenu } from '../../state/settings';
import { RootStateType } from '../../state/store';
import { Activable } from '../../types';
import { ChevronDownIcon } from '../icons/ChevronDownIcon';
import { CollapsibleContent } from './common/CollapsibleContent';
import { Language } from './Language';
import { Notifications } from './Notifications';
import { PhonePrefix } from './PhonePrefix';
import { Sounds } from './Sounds';

const CollapsibleSettings = styled(CollapsibleContent)`
  z-index: 10;

  position: absolute;
  top: 100%;
  left: var(--widget-content-padding);
  width: 100%;
`;
const SettingsElement = styled.div`
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 5px 10px;
`;
const SectionSelement = styled.div<Activable>`
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--widget-input-border-color);
  }
`;
const SectionHeadlineElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const HeadlineElement = styled.h4`
  margin: 10px 0;
`;
const InternalChevronicon = ({ active, ...rest }: {} & Activable) => (
  <ChevronDownIcon width={20} height={20} {...rest} />
);
const ChevronIcon = styled(InternalChevronicon)`
  g {
    transition: transform 0.3s ease-in-out;
    will-change: transform;
    transform-origin: center center;
    transform: rotateZ(-180deg);
  }

  ${({ active }) =>
    active &&
    css`
      g {
        transform: rotateZ(0deg);
      }
    `};
`;

type Props = {
  openButtonRef: React.RefObject<HTMLButtonElement>;
};

export const Settings = ({ openButtonRef }: Props) => {
  const {
    settings: { active },
  } = useSelector((e: RootStateType) => e);
  const dispatch = useDispatch();
  const { t } = useTranslation('settings');
  const [activeSection, setActiveSection] = React.useState(0);
  const [show, setShow] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, (e) => {
    // since settings button and this components don't have common ancestor
    // it's necesary to let button click handler run first and then run this
    // logic hence timeout is necesary
    window.setTimeout(() => {
      if (active) {
        dispatch(toggleMenu());
      }
    }, 100);
  });

  const onClick = (section: number) => {
    if (section === activeSection) {
      setActiveSection(0);
    } else {
      setActiveSection(section);
    }
  };

  React.useEffect(() => {
    window.setTimeout(() => {
      setShow(active);
    }, 100);
  }, [active]);

  return (
    <CollapsibleSettings active={show}>
      <SettingsElement ref={ref}>
        <SectionSelement active={activeSection === 1}>
          <SectionHeadlineElement onClick={() => onClick(1)}>
            <HeadlineElement>{t('personalization.headline')}</HeadlineElement>

            <ChevronIcon active={activeSection === 1} />
          </SectionHeadlineElement>

          <CollapsibleContent active={activeSection === 1}>
            <Language />

            <PhonePrefix />
          </CollapsibleContent>
        </SectionSelement>

        <SectionSelement active={activeSection === 2}>
          <SectionHeadlineElement onClick={() => onClick(2)}>
            <HeadlineElement>{t('sounds.headline')}</HeadlineElement>

            <ChevronIcon active={activeSection === 2} />
          </SectionHeadlineElement>

          <CollapsibleContent active={activeSection === 2}>
            <Sounds />
          </CollapsibleContent>
        </SectionSelement>

        <SectionSelement active={activeSection === 3}>
          <SectionHeadlineElement onClick={() => onClick(3)}>
            <HeadlineElement>{t('notifications.headline')}</HeadlineElement>

            <ChevronIcon active={activeSection === 3} />
          </SectionHeadlineElement>

          <CollapsibleContent active={activeSection === 3}>
            <Notifications />
          </CollapsibleContent>
        </SectionSelement>
      </SettingsElement>
    </CollapsibleSettings>
  );
};
