import * as React from 'react';
import styled from 'styled-components';
import { ClosePath } from '../icons/ClosePath';

const WrapperElement = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseButtonElement = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  display: flex;
  justify-self: flex-end;
  color: var(--widget-color);
`;

type Props = {
  onClick: () => void;
};

export const CloseButton: React.FC<Props> = ({ onClick }) => (
  <WrapperElement>
    <CloseButtonElement onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        focusable="false"
      >
        <ClosePath />
      </svg>
    </CloseButtonElement>
  </WrapperElement>
);
