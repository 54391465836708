import { css } from 'styled-components';

export const DropdownWrapperStyles = css`
  position: relative;

  display: flex;
  align-items: center;

  min-height: inherit;
  border-radius: 5px;
  border: 1px solid var(--widget-input-border-color);
  padding: 3px 5px;
  width: 125px;
`;

export const DropdownContentStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
`;

export const DropdownStyles = css<{ top: number; left: number }>`
  z-index: 11;

  position: fixed;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};

  display: none;
  background-color: var(--widget-content-background-color);
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  width: inherit;
  max-height: calc(2 * 5px + 5 * 36px);
  padding: 5px 0;
  overflow: auto;
`;

export const DropdownActiveStyles = css`
  display: block;
`;

export const DropdownItemStyles = css`
  display: flex;
  flex-direction: fow;
  align-items: center;

  padding: 0 5px;
  min-height: 36px;

  &:hover {
    background-color: var(--widget-input-border-color);
  }
`;
