import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { ShowOrHideNotification } from '../types';

type SetupPayload = {
  delay: number;
  duration: number;
  imageUrl: string | null;
  link: string;
  activeSounds: boolean;
  canShow: boolean;
};

type SetReshowPromoImagePayload = {
  after: Date;
};

type ChangeSoundPreferencePayload = {
  activeSounds: boolean;
};

type DeactivatePayload = {
  reshowAfter: Date;
  showOrHideNotification: ShowOrHideNotification;
};

export type NotificationState = {
  active: boolean;
  canShow: boolean;
  reshowAfter: Date | null;
  showOrHideNotification: ShowOrHideNotification;
  delay: number;
  duration: number;
  reshowPromoImageAfter: Date | null;
  imageUrl: string | null;
  link: string;
  activeSounds: boolean;
};

export const setupNotifications = createAction(
  '@notification/SETUP'
)<SetupPayload>();
export const showNotification = createAction('@notification/SHOW')();
export const hideNotification = createAction('@notification/HIDE')();
export const setReshowPromoImage = createAction(
  '@notification/SET_RESHOW_PROMO_IMAGE'
)<SetReshowPromoImagePayload>();
export const changeSoundPreference = createAction(
  '@notification/CHANGE_SOUND_PREFERENCE'
)<ChangeSoundPreferencePayload>();
export const deactivate = createAction(
  '@notification/DEACTIVATE'
)<DeactivatePayload>();
export const activate = createAction('@notification/ACTIVATE')();

export type NotificationAction =
  | ActionType<typeof setupNotifications>
  | ActionType<typeof showNotification>
  | ActionType<typeof hideNotification>
  | ActionType<typeof setReshowPromoImage>
  | ActionType<typeof changeSoundPreference>
  | ActionType<typeof deactivate>
  | ActionType<typeof activate>;

export const notificationReducer = createReducer<
  NotificationState,
  NotificationAction
>({
  active: false,
  canShow: true,
  reshowAfter: null,
  showOrHideNotification: ShowOrHideNotification.show,
  delay: 0,
  duration: 5000,
  reshowPromoImageAfter: null,
  imageUrl: '',
  link: '',
  activeSounds: false,
})
  .handleAction(setupNotifications, (state, action) => ({
    ...state,
    ...action.payload,
  }))
  .handleAction(showNotification, (state) => {
    if (state.reshowAfter && new Date() > state.reshowAfter) {
      return {
        ...state,
        active: true,
        canShow: true,
        reshowAfter: null,
      };
    }
    if (state.reshowAfter === null) {
      return { ...state, active: true };
    }

    return { ...state };
  })
  .handleAction(hideNotification, (state) => ({
    ...state,
    active: false,
    canShow: true,
  }))
  .handleAction(setReshowPromoImage, (state, action) => ({
    ...state,
    reshowPromoImageAfter: action.payload.after,
  }))
  .handleAction(changeSoundPreference, (state, action) => ({
    ...state,
    activeSounds: action.payload.activeSounds,
  }))
  .handleAction(deactivate, (state, action) => ({
    ...state,
    reshowAfter: action.payload.reshowAfter,
    showOrHideNotification: action.payload.showOrHideNotification,
  }))
  .handleAction(activate, (state) => ({
    ...state,
    reshowAfter: null,
    showOrHideNotification: ShowOrHideNotification.show,
  }));
