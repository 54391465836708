import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { activate, deactivate } from '../../state/notification';
import { RootStateType } from '../../state/store';
import { ShowOrHideNotification } from '../../types';
import { Dropdown, DropdownItem } from './common/Dropdown';
import { SettingsItem } from './styles/SettingsItem.styles';

const SoundsElement = styled.div`
  ${SettingsItem};
`;
const CustomDropdown = styled(Dropdown)`
  width: 150px;
`;

export const Notifications = () => {
  const { showOrHideNotification } = useSelector(
    (e: RootStateType) => e.notification
  );
  const dispatch = useDispatch();
  const { t } = useTranslation('settings');

  const onClick = (type: ShowOrHideNotification) => {
    if (type !== ShowOrHideNotification.show) {
      dispatch(
        deactivate({
          reshowAfter: _calculateReshowTime[type](),
          showOrHideNotification: type,
        })
      );
    } else {
      dispatch(activate());
    }
  };

  return (
    <SoundsElement>
      <span>{t('notifications.welcomeNotification.headline')}</span>

      <CustomDropdown
        placeholder={
          <Placeholder
            text={t(
              `notifications.welcomeNotification.options.${showOrHideNotification}`
            )}
          />
        }
      >
        {(onClose) =>
          Object.keys(ShowOrHideNotification).map((type) => (
            <DropdownItem
              key={type}
              onClick={() => {
                onClick(type as ShowOrHideNotification);
                onClose();
              }}
            >
              <Placeholder
                text={t(`notifications.welcomeNotification.options.${type}`)}
              />
            </DropdownItem>
          ))
        }
      </CustomDropdown>
    </SoundsElement>
  );
};

type PlaceholderProps = { text: string };
const Placeholder = ({ text }: PlaceholderProps) => <span>{text}</span>;

const _calculateReshowTime: Record<
  Exclude<ShowOrHideNotification, 'show'>,
  () => Date
> = {
  halfday: () => {
    const now = new Date();
    now.setTime(now.getTime() + 12 * 3600 * 1000);

    return now;
  },
  day: () => {
    const now = new Date();
    now.setTime(now.getTime() + 24 * 3600 * 1000);

    return now;
  },
  indefinitely: () => {
    const now = new Date();
    now.setFullYear(now.getFullYear() + 100);

    return now;
  },
};
