import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootStateType } from '../../state/store';
import { changeContent } from '../../state/view';
import { ContentType } from '../../types';
import { Button } from './Button';
import { Operators } from './Operators';

const GuidesElement = styled.div`
  padding: calc(var(--widget-content-padding) + 5px);

  button:last-of-type {
    margin-bottom: calc(-1 * var(--widget-subcontent-padding));
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: none;
  }
`;

export const Guides = () => {
  const {
    guidesPanel: { guides },
  } = useSelector((e: RootStateType) => e.global.options!);
  const dispatch = useDispatch();
  const { t } = useTranslation(['widget', 'custom_guides']);

  const onClick = (
    type: Extract<ContentType, 'help' | 'message' | 'callorder'>
  ) => dispatch(changeContent({ direction: 'forward', content: type }));

  return (
    <GuidesElement>
      <Operators />

      <Button onClick={() => onClick('help')}>{t('guides.help')}</Button>

      {guides &&
        guides.map((guide, index) => (
          <Button key={index} onClick={() => onClick(guide.component)}>
            {t(`custom_guides:${index + 1}`)}
          </Button>
        ))}
    </GuidesElement>
  );
};
