import { isEmpty } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { showWarningModal } from '../../state/modal';
import { RootStateType } from '../../state/store';
import { changeContent, setCanClose } from '../../state/view';
import { BackIcon } from '../icons/BackIcon';
import { GoBackWarningModal } from '../modals/GoBackWarningModal';
import { OpenChatWarningModal } from '../modals/OpenChatWarningModal';

const ActionsElement = styled.div`
  height: 24px;
  width: calc(100% + 2 * var(--widget-content-padding));
  display: flex;
  align-self: center;
  justify-content: space-between;
`;
const BackButtonElement = styled.button`
  width: 24px;
  height: 24px;
  color: var(--widget-color-green);
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background-color: transparent;
`;
const Headline = styled.span`
  font-weight: bold;
  font-size: 16px;
`;
const Spacer = styled.div`
  flex: 0 1 auto;
`;

type Props = {
  headline?: string;
};

export const Actions: React.FC<Props> = ({ headline }) => {
  const { canClose, activeContent } = useSelector((e: RootStateType) => e.view);
  const dispatch = useDispatch();

  const onClick = () => {
    if (!canClose) {
      if (activeContent === 'chat') {
        dispatch(
          showWarningModal({
            title: 'openChatWarning.title',
            text: 'openChatWarning.text',
            content: OpenChatWarningModal,
          })
        );
      } else {
        dispatch(
          showWarningModal({
            title: 'goBackWarning.title',
            text: 'goBackWarning.text',
            content: ({ elementRef, onCloseClick }) => {
              const onClick = () => {
                dispatch(setCanClose(true));
                dispatch(changeContent({ direction: 'backward' }));

                onCloseClick();
              };

              return (
                <GoBackWarningModal
                  elementRef={elementRef}
                  onCloseClick={onClick}
                />
              );
            },
          })
        );
      }
    } else {
      dispatch(changeContent({ direction: 'backward' }));
    }
  };

  return (
    <ActionsElement>
      <BackButtonElement type="button" onClick={onClick}>
        <BackIcon />
      </BackButtonElement>

      {!isEmpty(headline) && <Headline>{headline}</Headline>}

      <Spacer />
    </ActionsElement>
  );
};
