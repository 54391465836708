import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const WelcomeMessageElement = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  width: calc(var(--widget-width) - 40px);
  margin: 0 auto var(--widget-subcontent-padding) auto;
`;

const Headline = styled.h2`
  margin: 0;
  font-size: 24px;
`;

const Description = styled.span`
  font-size: 15px;
`;

export const WelcomeMessage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <WelcomeMessageElement>
      <Headline>{t('headline')}</Headline>
      <Description>{t('description')}</Description>
    </WelcomeMessageElement>
  );
};
